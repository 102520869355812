<template>
    <tr>
        <td>
            <router-link :to="{ name: 'SingleBarrel', params: { id: barrel.id } }" class="primary-text">
                {{ barrel.code }}
            </router-link>
        </td>
        <td>
            {{ barrel.mashbill.code }} 
        </td>
        <td>
            {{ barrel.barrelType.code }}
        </td>
    </tr>
</template>

<script>
export default {
    name: 'RickBarrels',
    props: ['barrel']
}
</script>