<template>
    <div class="row mt-5 mt-md-2" v-if="!loading">
        <div class="col-md-8 offset-md-2 mt-2 box">
            <h2 class="text-center text-caps mt-2">Companies</h2>
            <div class="row">
                <div class="col-md-6 text-center col-6">
                    <p>Name: </p>
                </div>
                <div class="col-md-6 text-center col-6">
                    <p>Updated at: </p>
                </div>
            </div>
            <single-company-list-item v-for="company in companies" :company="company" :key="company.id" />
            <div class="mb-3">
                <div class="text-center">
                    <button
                        class="btn btn-outline-primary circle-button"
                        data-bs-toggle="modal" 
                        data-bs-target="#addCompanyModal"
                        type="button"
                        v-tippy="{content: 'Add Company'}"
                    >
                        <i class="fa-solid fa-building"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addCompanyModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Company</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-2">
                            <p class="red">{{companyError}}</p>
                            <label for="companyCode" class="form-label">Code <span class="red">*</span></label>
                            <input 
                                type="text" 
                                v-model.trim="companyCode" 
                                required
                                class="form-control"
                                placeholder="Company Code"
                                id="companyCode"
                            />
                            <span class="regular-text">Max characters 4</span>
                        </div>
                        <div class="mb-2">
                            <label for="companyName" class="form-label">Name <span class="red">*</span></label>
                            <input 
                                type="text" 
                                v-model.trim="companyName" 
                                required
                                class="form-control"
                                placeholder="Company Name"
                                id="companyName"
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button 
                            type="button" 
                            class="btn btn-outline-danger" 
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button 
                            type="button" 
                            class="btn btn-outline-primary" 
                            @click.prevent="createCompany"
                        >
                            Save Company
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import SingleCompanyListItem from '@/components/company/SingleCompanyListItem.vue';
import AppLoader from '@/components/AppLoader.vue';
import {getCompanies, createNewCompany} from '../utils/auth';
import Apploader from '@/components/AppLoader.vue';
import {mapState} from 'vuex';

export default {
    name: 'Companies',
    data() {
        return {
            companies: null,
            loading: true,
            displayCompany: false,
            companyCode: "",
            companyName: "",
            companyError: ""
        }
    },
    components: {
        SingleCompanyListItem,
        AppLoader
    },
    computed: {
        ...mapState(['accessToken'])
    },
    methods: {
        createCompany() {
            if(this.companyName == '' || this.companyCode == '' || this.companyCode.length < 4) this.companyError = 'All fields must be entered correctly'
            else {
                createNewCompany(this)
            }
        }
    },
    beforeMount() {
        getCompanies(this);
    },
}
</script>

<style scoped>
    .circle-button {
        border-radius: 50%;
    }
</style>
