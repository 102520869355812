<template>
    <div class="row">
        <div class="col-md-6 offset-md-3 card-like">
            <p class="errMsg">
                {{errMsg}}
            </p>
            <form >
                <div class="mb-2">
                    <label for="username" class="form-label">Username</label>
                    <input 
                        type="text" 
                        placeholder="Username"
                        id="username" 
                        class="form-control" 
                        v-model="username" 
                        autocomplete="off"
                        required 
                    />
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        v-model="password"
                        required
                        id="password"
                        autocomplete="off"
                    />
                </div>
                <div class="mb-2 text-center">
                    <button @click.prevent="signIn" type="submit" class="btn btn-outline-secondary">Sign In</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
const RickhouseApi = require('rickhouse_api');
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
    name: 'SignInForm',
    data() {
        return {
            username: '',
            password: '',
            userData: null,
            errMsg: ''
        }
    },
    computed: {
        ...mapState(['userLoggedIn'])
    },
    methods: {
        ...mapActions(['login']),
        ...mapMutations(['getCompanyId']),
        signIn() {
            let api = new RickhouseApi.AuthApi();
            let authSignInPost = new RickhouseApi.AuthSignInPost(this.username, this.password)
            api.signIn(authSignInPost, (error, data, response) => {
                if (error) {
                    if(error.status >= 400 && error.status < 500) {
                        this.errMsg = "Invalid username or password"
                        this.username = "";
                        this.password = "";
                    }
                } else {
                    this.$store.commit('getCompanyId', data.company)
                    this.$store.dispatch('login', data.token);
                    this.errMsg = '';
                    setTimeout(() => {
                        this.$router.push({name: 'Rickhouses'});
                    }, 250)
                }
            }); 
        }           
    }
}
</script>

<style scoped>
.card-like {
    margin-top: 80px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.errMsg {
    color: #cb4154;
}

</style>