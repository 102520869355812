<template>
    <div class="row" v-if="loading">
        <app-loader />
    </div>
    <div class="barrel-column text-center" v-else-if="filteredBarrels.length == 0">
        <h2 class="text-center mt-4">There are no barrels selected</h2>
        <router-link :to="{ name: 'BarrelList' }">
            <button class="btn btn-outline-info mt-2">
                <i class="fa-solid fa-arrow-left"></i>
                Go Back to Barrel List
            </button>
        </router-link>
    </div>
    <div class="barrel-column" v-else>
        <h1 class="text-center barrel-header">Filtered Barrels</h1>
        <div class="text-center">
            <button class="btn btn-outline-primary" @click="exportToCSV">
                Export to CSV
            </button>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2 box mt-4" v-for="barrel in filteredBarrels" :key="barrel.id">
                <router-link :to="{ name: 'SingleBarrel', params: { id: barrel.id } }" class="primary-text">
                    <h3 class="text-center">{{ barrel.code }}</h3>
                </router-link>
                <div class="row">
                    <div class="col-md-6 col-6">
                        <p><strong>Proof:</strong> {{ barrel.proof }}</p>
                        <p><strong>Gallons:</strong> {{ barrel.gallons }}</p>
                        <p><strong>Proof/Gallons:</strong> {{ barrel.proofGallons }}</p>
                        <p><strong>Company:</strong> {{ barrel.company.code || 'Not specified' }}</p>
                        <p><strong>Mashbill:</strong> {{ barrel.mashbill.code }}</p>
                        <p><strong>Barrel Type:</strong> {{ barrel.barrelType.code }}</p>
                        <p><strong>Status:</strong> {{ barrel.status }}</p> 
                    </div>
                    <div class="col-md-6 col-6">
                        <p><strong>Last Updated:</strong> {{ barrel.updatedAt.toDateString() }}</p>
                        <p><strong>Rick:</strong> {{ barrel.rick.code || 'Not specified' }}</p>
                        <p><strong>Location:</strong></p>
                        <p><strong>Floor: {{ barrel.rick.properties.floor }}</strong></p>
                        <p><strong>Level: {{ barrel.rick.properties.level }}</strong></p>
                        <p><strong>Row: {{ barrel.rick.properties.row }}</strong></p>
                        <p><strong>Rickhouse: </strong>{{ barrel.rick.rickhouse.code || 'Not Specified' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import { onUnmounted } from '@vue/runtime-core'
import AppLoader from '../components/AppLoader.vue'

export default {
    components: { AppLoader },
    name: 'BarrelListSelected',
    data() {
        return {
            loading: true,
            filteredBarrels: []
        }
    },
    mounted() {
        this.filteredBarrels = this.$store.state.barrelListSelection
        this.loading = false
    },
    unmounted() {
        this.$store.state.barrelListSelection = []
        this.$store.state.cBoxStatus = false
    },
    methods: {
        exportToCSV() {
            const dataHeaders = [
                'age time and delta',
                'age days and years',
                'barrel type code',
                'code',
                'company',
                'created at',
                'external code',
                'filled at',
                'gallons',
                'mashbill code',
                'notes',
                'position',
                'pounds',
                'proof',
                'proof gallons',
                'rick code',
                'status',
                'updated at'
            ]
            const csvString = [
                dataHeaders,
                ...this.filteredBarrels.map(barrel => [
                    barrel.ageTimedelta,
                    `${barrel.ageYearsAndDays[0]} years and ${barrel.ageYearsAndDays[1]} days`,
                    barrel.barrelType.code,
                    barrel.code,
                    barrel.company.code,
                    barrel.createdAt,
                    barrel.externalCode,
                    barrel.filledAt,
                    barrel.gallons,
                    barrel.mashbill.code,
                    barrel.notes,
                    barrel.position,
                    barrel.pounds,
                    barrel.proof,
                    barrel.proofGallons,
                    barrel.rick.code,
                    barrel.status,
                    barrel.updatedAt
                ])
            ].map(e => e.join(",")) 
             .join("\n")

            const csvFormat = "data:text/csv;charset=utf-8," + csvString
            
            const encodeUri = encodeURI(csvFormat)
            window.open(encodeUri)
        }
    }

}
</script>

<style scoped>
.barrel-column {
    flex-direction: column;
}

.barrel-header {
    font-variant: small-caps;
}

</style>