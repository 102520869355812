<template>
    <div class="row mt-5" v-if="loading == false">
            <div class="col-md-8 offset-md-2 box pb-4 mb-4">
                <h2 class="text-center text-caps">Rickhouses</h2>
                <div class="row">
                    <div class="col-md-6 col-6">
                        <p class="text-center">Name: </p>
                    </div>
                    <div class="col-md-6 col-6">
                        <p class="text-center">Last Updated</p>
                    </div>
                </div>
                <div class="row">
                    <rickhouse-single-list-item v-for="rickhouse in rickhouses" :key="rickhouse.id" :rickhouse="rickhouse" />
                </div>
                <div class="mb-3">
                    <div class="text-center">
                        <button 
                            class="btn btn-outline-primary circle-button"
                            data-bs-toggle="modal" 
                            data-bs-target="#toggleRickhouseModal"
                            v-tippy="{content: 'Add'}"
                        >
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
        </div>
        <div class="modal fade" id="toggleRickhouseModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Rickhouse</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-2">
                            <label for="rCode" class="form-label">Rickhouse Code <span class="red">*</span></label>
                            <input
                                type="text"
                                max="4"
                                id="rCode"
                                placeholder="Rickhouse Code"
                                v-model.trim="rCode"
                                class="form-control"
                            />
                            <span class="regular-text">Max length 4</span>
                        </div>
                        <div class="mb-2">
                            <label for="rName">Rickhouse Name <span class="red">*</span></label>
                            <input
                                type="text"
                                v-model.trim="rName"
                                class="form-control"
                                id="rName"
                                placeholder="Rickhouse Name"
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button 
                            type="button" 
                            class="btn btn-outline-danger" 
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button 
                            type="button" 
                            class="btn btn-outline-primary" 
                            data-bs-dismiss="modal"
                            @click="createRickhouse"
                        >
                            Add Rickhouse
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import RickhouseSingleListItem from '@/components/rickhouses/RickhouseSingleListItem.vue';
import {readRickhouses, createNewRickhouse} from '@/utils/auth';
import AppLoader from '@/components/AppLoader.vue'
import {mapState} from 'vuex';

export default {
    name: 'Rickhouses',
    data() {
        return {
            rickhouses: null,
            rCode: "",
            rName: "",
            loading: true
        }
    },
    components: {
        RickhouseSingleListItem,
        AppLoader  
    },
    computed: {
        ...mapState(['accessToken'])
    },
    methods: {
        createRickhouse() {
            createNewRickhouse(this);
        }
    },
    beforeMount() {
        readRickhouses(this);
    }  
}
</script>

<style scoped>
.circle-button {
    border-radius: 50%;
}
</style>