<template>
    <div v-if="!loading" class="row">
        <div class="col-md-10 offset-md-1 box mt-5">
            <h3 class="text-center">Reconciliation #{{reconciliation.id.substr(0,12)}}</h3>     
            <h5 class="text-center">
                Status <span :class="reconciliation.status == 'created' ? 'badge bg-aqua' : 'badge bg-heather'">{{reconciliation.status}}</span>
            </h5>
            <div class="row mb-4">
                
            </div>
            <div class="row">
                <h5 class="text-center">
                    Details
                </h5>
                <div class="col-md-6 col-6">
                    <p><strong>Active: </strong>{{reconciliation.isActive}}</p>
                    <p><strong>Updated at: </strong>{{ reconciliation.updatedAt.toDateString() }}</p>
                    <p v-if="reconciliation.items.length"><strong>Items: </strong></p>
                    <!-- <p v-else v-for="rec in reconciliation.items" :key="rec">Items: {{rec}} </p> -->
                </div>
                <div class="ccol-md-6 col-6">
                    <p><strong>Rick: </strong>{{ reconciliation.rick.code }}</p>
                    <p><strong>Name: </strong>{{ reconciliation.rick.name }}</p>
                    <p><strong>Barrel / Capacity: </strong>{{reconciliation.rick.barrelCount}} / {{ reconciliation.rick.barrelCapacity }}</p>
                    
                </div>
            </div>
            <div class="accordian" id="recActions">
                <div class="accordian-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button 
                            class="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                        >
                            Actions
                        </button>
                    </h2>
                    <div 
                        id="collapseOne" 
                        class="accordion-collapse collapse"
                        data-bs-parent="#recActions"
                    >
                        <div class="accordion-body">
                            <rec-actions v-for="action in rActions" :key="action.id" :action="action" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="endRec">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">End Reconciliation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="addRecRemove"></button>
                    </div>
                    <div class="modal-body">
                        <p class="text-center">Are you sure you want to end reconciliation?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" @click="endRec">End Reconciliation</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue'
import {getReconciliationsById, getReconciliationActions, removeReconciliation} from '@/utils/auth'
import RecActions from '@/components/actions/RecActions.vue'

export default {
    name: 'Reconciliations',
    props: ['rickId'],
    data() {
        return {
            reconciliation: null,
            loading: true,
            rActions: null
        }
    },
    components: {
        AppLoader,
        RecActions
    },
    methods: {
        endRec() {
            removeReconciliation(this)
        }
    },
    mounted() {
        getReconciliationsById(this) 
        getReconciliationActions(this) 
    }
}
</script>