<template>
    <div class="row not-found">
        <i class="fa-solid fa-triangle-exclamation user"></i>
        <h1 class="text-center text-caps">Oops, Something went wrong. </h1>
        <p class="text-center">Please make sure you are going to the right place</p>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>

<style scoped>
.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

</style>
