<template>
    <div class="row mt-5 mt-md-2" v-if="!loading">
        <div class="col-md-10 offset-md-1">
            <h2 class="text-center">
                {{ mashbill[0].name }}
            </h2>
            <p class="text-center">
                Created at: {{ mashbill[0].createdAt.toDateString() }}
            </p>
            <p class="text-center">
                Loast Updated: {{ mashbill[0].updatedAt.toDateString() }}
            </p>
            <h3 class="text-center text-caps">Barrels with Mashbill</h3>
            <single-barrel-list-item :barrel="barrel" v-for="(barrel, key) in barrels" :key="key" />
            <div class="row mb-2">
                    <div class="col-md-2 offset-md-1">
                        <select class="form-select" v-model="perPage" @change="handleSizeChange">
                            <option disabled>--select page size--</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div> 
                    <div class="col-md-8">
                        <ul class="pagination">
                            <li 
                                class="page-item" 
                                v-for="idx in allPages" 
                                :key="idx" 
                                ref="pagList"
                            >
                                <input 
                                    class="page-link" 
                                    type="button"
                                    :value="idx"  
                                    @click.prevent="getPagValue(idx)"                
                                />
                            </li>
                        </ul>
                    </div> 
                </div>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="accordian mb-2" id="accordianActions">
                        <h1 class="accordian-title">
                            <button class="accordion-button mt-2 mb-2" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <strong> Actions </strong>
                            </button>
                        </h1>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <common-actions v-for="action in mashbillActions" :key="action.id" :action="action" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import SingleBarrelListItem from '@/components/barrels/SingleBarrelListItem.vue';
import CommonActions from '@/components/actions/CommonActions.vue';
import { readMashBill, readBarrelByMashBill, editMashBill, getMashBillActions } from '@/utils/auth';
import AppLoader from '@/components/AppLoader.vue';
import { mapState } from 'vuex';

export default {
    name: 'SingleMashbill',
    data() {
        return {
            mashbill: [],
            loading: true,
            displayEditToggle: false,
            mashbillName: '',
            mashBillCode: '',
            barrels: [],
            mashbillActions: [],
            pageNo: 1,
            allPages: 1,
            perPage: 25
        }
    },
    computed: {
        ...mapState(['accessToken'])
    },
    components: {
        SingleBarrelListItem,
        CommonActions,
        AppLoader
    },
    methods: {
        updateMashBill() {
            editMashBill(this);
        },
        handleSizeChange() {
            readBarrelByMashBill(this, this.perPage, this.currentPage)
        },
        handleCurrentChange() {
            readBarrelByMashBill(this, this.perPage, this.currentPage)
        },
        getPagValue(btnValue) {
            this.pageNo = btnValue
            readBarrelByMashBill(this)
        }
    },
    beforeMount() {
        readMashBill(this);
        readBarrelByMashBill(this, this.perPage, this.currentPage);
        getMashBillActions(this);
    }
}
</script>

<style scoped>

</style>