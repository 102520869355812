<template>
    <div class="container-fluid" v-if="rick != null">
        <div class="col-md-10 offset-md-1 box mt-4">
            <div class="row">
                <h2 class="text-center">Rick: {{ rick.name }}</h2>
                <p><strong>Code: </strong>{{rick.code}}</p>
                <p><strong>Updated at: </strong>{{ rick.updatedAt.toDateString() }}</p>
                <p><strong>Count / Capacity: </strong> {{rick.barrelCount}} / {{rick.barrelCapacity}}</p>
                <!-- <div class="col-md-4">
                    <h5 class="mt-1">Rick Label </h5>
                    <p>{{`${rickLabel.substring(0, 12)}...`}}</p>
                </div> -->
                <div class="col-md-3 mb-2 ms-2">
                    <button 
                        class="btn btn-outline-primary"
                        @click="printLabel(rickLabel)"
                    >
                        Print Label
                    </button>
                </div>
                <div class=" mb-5">
                    <div class="text-center">
                        <button 
                            data-bs-toggle="modal" 
                            data-bs-target="#addRec"
                            class="circle-button btn btn-outline-primary small-caps circle-button"
                            v-tippy="{content: 'Start Reconciliation'}"
                            v-if="!rick.reconciliationStatus"
                        >
                        <i class="fa-solid fa-play"></i>
                        </button>
                        <router-link 
                            :to="{name: 'RecSelect', params: {id: reconciliations[reconciliations.length - 1].id}}"
                            v-if="reconciliations"
                        >
                            <button 
                                class="btn btn-outline-info circle-button"
                                v-tippy="{content: 'Update Reconciliation'}"
                                v-if="rick.reconciliationStatus && rick.reconciliationStatus == 'created'"
                            >
                                <i class="fa-solid fa-pen-nib"></i>
                            </button>
                        </router-link>
                        <button 
                            data-bs-toggle="modal" 
                            data-bs-target="#endRec"
                            class="circle-button btn btn-outline-danger ps-3 pe-3"
                            v-tippy="{content: 'End Reconciliation'}"
                            v-if="rick.reconciliationStatus && rick.reconciliationStatus == 'started'"
                        >
                            <i class="fa-solid fa-xmark"></i>
                        </button>

                    </div>
                </div>
                <h4 class="text-center text-caps">Barrels</h4>
                <div class="container">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Barrel
                                </th>
                                <th>
                                    Mashbill
                                </th>
                                <th>
                                    Barrel Type
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <rick-barrels v-for="barrel in barrels" :key="barrel.id" :barrel="barrel" />
                        </tbody>
                    </table>
                </div>
                <div class="row" v-if="!barrels.length">
                    <no-items-found noun="barrels" />
                </div>
            </div>
            <div class="row" v-if="reconciliations">
                <div class="container">
                    <h3 class="text-center text-caps">Reconciliations</h3>
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Active</th>
                                <th>Status</th>
                                <th>OK</th>
                                <th>Created</th>
                                <th>Creator</th>
                            </tr>
                        </thead>
                        <tbody>
                            <rec-list-item 
                                v-for="reconciliation in reconciliations" 
                                :key="reconciliation.id" 
                                :rickId="rick.id" 
                                :reconciliation="reconciliation"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <button 
                        v-if="!barrels.length"
                        class="btn btn-outline-danger"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal"
                    >
                        Remove Rick
                    </button>
                </div>
            </div>
            <div class="accordian mb-5" id="accordianActions">
                <h1 class="accordian-title">
                    <button class="accordion-button mt-2 mb-2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <strong> Actions </strong>
                    </button>
                </h1>
                <div 
                    id="collapseOne" 
                    class="accordion-collapse collapse" 
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                >
                    <div class="accordion-body">
                        <common-actions v-for="action in rickActions" :key="action.id" :action="action" /> 
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to delete the rick?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-outline-primary" @click="deleteRick">Delete Rick</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="endRec">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">End Reconciliation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="addRecRemove"></button>
                    </div>
                    <div class="modal-body">
                        <p class="text-center">Are you sure you want to end reconciliation?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" @click="endRec">End Reconciliation</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addRec">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Reconciliation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="addRecRemove"></button>
                    </div>
                    <div class="modal-body">
                        <p class="text-center">Are You sure you want to start reconciliation for {{rick.name}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button 
                            type="button" 
                            class="btn btn-outline-primary"
                            @click="startRec"
                            data-bs-dismiss="modal"
                        >
                            Start Reconciliation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue'
import RickBarrels from '@/components/rick/RickBarrels.vue'
import NoItemsFound from '@/components/common/NoItemsFound.vue'
import CommonActions from '@/components/actions/CommonActions.vue'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'
import RecListItem from '@/components/reconciliation/RecListItem.vue'
import { useToast } from "vue-toastification";
import { 
    readBarrelsByRick, 
    readRick, 
    getRickActions, 
    getRicksLabel,
    postReconciliation, 
    removeRick,
    removeReconciliation
} from '@/utils/auth'
import { mapState } from 'vuex'

export default {
    name: 'SingleRick',
    data() {
        return {
            barrels: [],
            rick: null,
            loading: true,
            rickLabel: '',
            rickActions: [],
            perPage: 25,
            currentPage: 1,
            reconcilationStatus: null,
            reconciliations: null
        }
    },
    computed: {
        ...mapState(['accessToken'])
    },
    components: {
        AppLoader,
        RickBarrels,
        CommonActions,
        NoItemsFound,
        RecListItem
    },
    methods: {
        handleSizeChange() {
            readBarrelsByRick(this, this.perPage, this.currentPage)
        },
        handleCurrentChange() {
            readBarrelsByRick(this, this.perPage, this.currentPage)
        },
        async printLabel(serial) {
            const toast = useToast()
            try {
                const browserPrint =  new ZebraBrowserPrintWrapper()
                const defaultPrinter =  await browserPrint.getDefaultPrinter()
                browserPrint.setPrinter(defaultPrinter)
                const printerStatus = await browserPrint.checkPrinterStatus()

                if(printerStatus.isReadyToPrint) {
                    const zpl = `^XA
                        ^BY2,2,100
                        ^FO20,20^BC^FD${serial}^FS
                        ^XZ`
                    browserPrint.print(zpl)
                    toast.success('Printer has begun printing')
                }
                else {
                    console.warn("Error/s: ", printerStatus.errors)
                    toast.warning(`The printer had shows error(s) of ${printerStatus.errors}`)
                }
            }
            catch(error) {
                console.warn('error in catch block', error)
            }
        },
        deleteRick() {
            removeRick(this)
        },
        startRec() {
            postReconciliation(this)  
        },
        endRec() {
            removeReconciliation(this)
        }
    },
    created() {
        readRick(this)
        readBarrelsByRick(this)
        getRickActions(this)
        getRicksLabel(this)
    }
}
</script>

<style scoped>

</style>