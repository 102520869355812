<template>
    <div class="row mt-5" v-if="!loading">
        <div class="col-md-10 offset-md-1 box">
            <div class="row mx-auto">
                <i class="fa-solid fa-user-tie tie"></i>
                <h2 class="text-center">
                    {{user.name}}
                </h2> 
            </div>
            <div class="row">
                <p class="primary-text ms-2"><strong>Created at: </strong>{{user.createdAt.toDateString()}}</p>
                <p class="primary-text ms-2"><strong>Updated: </strong>{{user.updatedAt.toDateString() }}</p>
                <p class="primary-text ms-2"><strong>Permissions</strong></p>
                <ul class="ms-3">
                    <li class="regular-text" v-for="(permission, key) in user.permissions" :key="key">
                        {{permission.replaceAll('_', ' ')}}
                    </li>
                </ul>
                <div class="text-center mb-4">
                    <button 
                        class="btn btn-outline-info round-button new-btn" 
                        v-tippy="{ content: 'Update User' }"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                    >
                        <i class="fa-sharp fa-light fa-pen-nib"></i>
                    </button>
                </div> 
                 
            </div>
            <div class="row">
                    <div class="accordian" id="barrelTypeActions">
                        <div class="accordian-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button 
                                    class="accordion-button" 
                                    type="button" 
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" 
                                    aria-expanded="true" 
                                    aria-controls="collapseOne"
                                >
                                    Actions
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse"
                                data-bs-parent="#barrelTypeActions">
                                <div class="accordion-body">
                                    <common-actions v-for="action in userActions" :key="action.id" :action="action" />
                                </div>
                            </div>
                        </div>
                    </div>
            </div> 
        </div>
        <div class="modal fade" id="updateModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="username" class="form-label">User Name: </label>
                            <input type="text" class="form-control" id="username" v-model="userName">
                        </div>
                        <div class="form-group mt-2">
                            <label for="password" class="form-label">Password: </label>
                            <input type="text" id="password" class="form-control" v-model="userPassword">
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check d-inline-block">
                                    <input class="form-check-input" type="checkbox" value="" id="createCheck" ref="createP">
                                    <label class="form-check-label" for="createCheck">
                                    Create permissions
                                    </label>
                                </div>
                                <div class="form-check d-inline-block mt-2">
                                    <input class="form-check-input" type="checkbox" id="readCheck" ref="readP">
                                    <label class="form-check-label" for="readCheck">
                                    Read permissions
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check d-inline-block ml-2">
                                    <input class="form-check-input" type="checkbox" id="updateCheck" ref="updateP">
                                    <label class="form-check-label" for="updateCheck">
                                    Update permissions
                                    </label>
                                </div>
                                
                                <div class="form-check d-inline-block ml-2 mt-2">
                                    <input class="form-check-input" type="checkbox" id="deleteCheck" ref="deleteP">
                                    <label class="form-check-label" for="deleteCheck">
                                    Delete permissions
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" :disabled="disabledStat" class="btn btn-primary ms-2" data-bs-dismiss="modal" @click.prevent="updateCurrentUser">Update User</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import CommonActions from '@/components/actions/CommonActions.vue';
import {
        getUserById, 
        getUserActions, 
        updateUser 
    } from '@/utils/auth';

export default {
    name: 'SingleUser',
    data() {
        return {
            user: null,
            loading: true,
            userActions: [],
            userName: '',
            userPassword: '',
            disabledStat: true,
            permissions: [
               'access_inactive_objects', 'access_all_companies', 'access_all_users'
            ],
            readEndpoint: [
                "read_actions_by_barrel",
                "read_actions_by_barrel_type",
                "read_actions_by_company",
                "read_actions_by_device_measurement",
                "read_actions_by_mashbill",
                "read_actions_by_rick",
                "read_actions_by_rickhouse",
                "read_actions_by_user",
                "read_barrel",
                "read_barrel_by_code",
                "read_barrel_type",
                "read_barrel_types",
                "read_barrels",
                "read_barrels_by_barrel_type",
                "read_barrels_by_filter",
                "read_barrels_by_mashbill",
                "read_barrels_by_rick",
                "read_companies",
                "read_company",
                "read_device_measurement",
                "read_device_measurements",
                "read_mashbill",
                "read_mashbills",
                "read_rick",
                "read_rick_by_code",
                "read_rickhouse",
                "read_rickhouses",
                "read_ricks",
                "read_user",
                "read_user_token",
                "read_user_tokens",
                "read_users",
                "read_label_by_rick",
                "read_label_by_barrel"
            ],
            updateEndpoint: [
                "update_barrel",
                "update_barrel_rick",
                "update_barrel_status",
                "update_barrel_type",
                "update_company",
                "update_mashbill",
                "update_rick",
                "update_rickhouse"
            ],
            createEndpoint: [
                "create_barrel",
                "create_barrel_type",
                "create_company",
                "create_device_measurement",
                "create_mashbill",
                "create_rick",
                "create_rickhouse",
                "create_user"
            ],
            deleteEndpoint: [
                "delete_rick",
                "delete_rickhouse",
                "delete_user_token",
            ],
            endpoints: ["sign_out"],
        }
    },
    components: {
        AppLoader,
        CommonActions
    },
    beforeMount() {
        getUserById(this)
        getUserActions(this);
    },
    methods: {
        updateCurrentUser() {
            if(this.$refs.readP.checked) {
                this.endpoints.push(...this.readEndpoint)
            }
            if(this.$refs.createP.checked) {
                this.endpoints.push(...this.createEndpoint)
            }
            if(this.$refs.updateP.checked) {
                this.endpoints.push(...this.updateEndpoint)
            }
            if(this.$refs.deleteP.checked) {
                this.endpoints.push(...this.deleteEndpoint)
            }
            updateUser(this)    
        },
    },
    updated() {
        this.user.name = this.userName == '' ? this.user.name : this.userName
    },
    watch: {
        userName() {
            if(this.userName) this.disabledStat = false
            else this.disabledStat = true
        },
        userPassword() {
            if(this.userPassword) this.disabledStat = false
            else this.disabledStat = true
        }
    }
}
</script>

<style scoped>
ul {
    list-style: none;
    padding-left: 0;
}
li {
    margin-bottom: 8px;
}

.tie {
    height: 64px;
    color: #B83A4B;
}

.round-button {
    border-radius: 50%;
}


@media only screen and (max-width: 600px) {
    .new-btn {
        width: 40px;
    }
}
</style>