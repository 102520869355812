<template>
    <p>
        {{`There are no ${noun} currently`}}
    </p>
</template>

<script>
export default {
    name: 'NoItemsFound',
    props: ['noun']
}
</script>