<template>
<router-link class="primary-text" :to="{name: 'SingleCompany', params: {id: company.id}}">
    <div class="row single-list">
        <div class="col-md-6 text-center col-6">
            <p>
                {{company.name}}
            </p>
        </div>
        <div class="col-md-6 text-center col-6">
            <p>
                {{company.updatedAt.toDateString()}}
            </p>
        </div>
    </div> 
</router-link>
</template>

<script>
export default {
    name: 'Compnay',
    props: ['company']
}
</script>