<template>
    <div class="container-fluid" v-if="rickhouse !== null">
        <div class="row">
            <div class="col-md-10 offset-md-1 box mt-4">
                <h2 class="text-center text-caps">{{this.rickhouse.name}} ricks</h2>
                <table class="table table-hover table-striped" v-if="ricks.length">
                    <thead>
                        <tr>
                            <th>Name:</th>
                            <th>Updated at: </th>
                            <th>Reconciliation: </th>
                        </tr>
                    </thead>
                    <tbody>
                        <single-rick-list-item v-for="rick in ricks" :key="rick.id" :rick="rick" /> 
                    </tbody>
                </table>
                <h5 class="text-center" v-if="!ricks.length">There are no ricks assigned for this rickhouse.</h5>
                <div>
                    <div class="text-center pb-3">
                        <button 
                            class="btn btn-outline-primary circle-button"
                            type="button"
                            data-bs-toggle="modal" 
                            data-bs-target="#addRickModal"
                            v-tippy="{content: 'Add Rick'}"
                        >
                            <i class="fa-solid fa-plus"></i>
                        </button>
                        <button 
                            class="btn btn-outline-info circle-button ms-2"
                            type="button"
                            data-bs-toggle="modal" 
                            data-bs-target="#updateRickModal"
                            v-tippy="{content: 'Edit Rickhouse'}"
                        >
                            <i class="fa-solid fa-pen-nib"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addRickModal" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Rick</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="red">{{rickError}}</p>
                        <div class="mb-3">
                            <label for="rickCode" class="form-label">Code</label>
                            <input
                                type="text" 
                                v-model="rickCode" 
                                required
                                placeholder="Rick Code"
                                id="rickCode"
                                class="form-control"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="rickName" id="rickName">Name</label>
                            <input 
                                type="text" 
                                v-model="rickName" 
                                required
                                class="form-control"
                                placeholder="Rick Name"
                                id="rickName"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="barrelCap" class="form-label">Barrel Capacity</label>
                            <input
                                type="number"
                                id="barrelCap"
                                class="form-control"
                                placeholder="Barrel Capacity"
                                v-model="barrelCapacity"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="floor" class="form-label">Floor</label>
                            <input
                                type="number"
                                id="floor"
                                class="form-control"
                                placeholder="Floor"
                                v-model="floor"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="row" class="form-label">Row</label>
                            <input
                                type="number"
                                id="row"
                                class="form-control"
                                placeholder="Row"
                                v-model="row"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="level" class="form-label">Level</label>
                            <input
                                type="text"
                                class="form-control"
                                id="level"
                                placeholder="Level"
                                v-model.trim="level"
                            />
                        </div>
                    </div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="createRick">Add Rick</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="updateRickModal" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Rickhouse</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeUpdateModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="rName" class="form-label">Name</label>
                            <input
                                type="text"
                                class="form-control"
                                id="rName"
                                placeholder="Name"
                                v-model.trim="rName"
                            />
                        </div>
                    </div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="editRickhouse">Update Rickhouse</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import NoItemsFound from '@/components/common/NoItemsFound.vue';
import SingleRickListItem from '@/components/rick/SingleRickListItem.vue';
import {mapState} from 'vuex';

import {
    getRickhouseById,
    updateRickhouse, 
    removeRickhouse, 
    getRicksByRickhouseId,
    createRickByRickhouseId
    } from '@/utils/auth';

export default {
    name: 'RickhouseOverview',
    data() {
        return {
            rickhouse: null,
            loading: true,
            ricks: [],
            rickName: '',
            rickCode: '',
            rickhouseCode: '',
            rickhouseName: '',
            perPage: 25,
            currentPage: 1,
            errMsg: '',
            barrelCapacity: 0,
            floor: 0,
            level: '',
            row: 0,
            rickError: '',
            rName: ''
        }
    }, 
    components: {
        AppLoader,
        NoItemsFound,
        SingleRickListItem
    },
    computed: {
        ...mapState(['accessToken'])
    },
    methods: {
        createRick() {
            if(this.rickCode == '' || this.rickName == '' || this.barrelCapacity == 0 || this.floor == 0 || this.row == 0 || this.level == '') this.rickError = 'All fields must be entereed correctly'
            else createRickByRickhouseId(this);
            
        },
        editRickhouse() {
            updateRickhouse(this);
        },
        deleteRickhouse() {
            removeRickhouse(this)
        },
        handleSizeChange() {
            getRicksByRickhouseId(this,this.perPage, this.currentPage);
        },
        handleCurrentChange() {
            getRicksByRickhouseId(this, this.perPage, this.currentPage);
        }
    },
    beforeMount() {
        getRickhouseById(this);
        getRicksByRickhouseId(this);
    },
    updated() {
        this.rickhouse.name = this.rName  == '' ? this.rickhouse.name : this.rName
    }
}
</script>

<style scoped>
.m-top {
    margin-top: 20px;
}

.circle-button {
    border-radius: 50%;
}
</style>