import createStore from '../store/index'
import { useToast } from "vue-toastification"
const RickhouseApi = require('rickhouse_api')
let defaultClient = RickhouseApi.ApiClient.instance
const HTTPBearer = defaultClient.authentications['HTTPBearer']
HTTPBearer.accessToken = createStore.state.accessToken
const toast = useToast()
import store from '../store/index'

if(window.location.hostname.includes('lmapi.runcheck.xyz')) {
    RickhouseApi.ApiClient.instance.basePath = 'https://lmapi.runcheck.xyz'
}
else if(window.location.hostname.includes('lucamariano.net')) { 
    RickhouseApi.ApiClient.instance.basePath = 'https://api.lucamariano.net'
}
// instances
const authInstance = new RickhouseApi.AuthApi()
const barrelInstance = new RickhouseApi.BarrelsApi()
const barrelTypeInstance = new RickhouseApi.BarrelTypesApi()
const companyInstance = new RickhouseApi.CompaniesApi()
const mashBillsInstance = new RickhouseApi.MashbillsApi()
const rickhouseInstance = new RickhouseApi.RickhousesApi()
const ricksInstance = new RickhouseApi.RicksApi()
const userInstance = new RickhouseApi.UsersApi()
const userMeasuremntInstance = new RickhouseApi.DeviceMeasurementsApi()
const reconciliationInstance = new RickhouseApi.ReconciliationsApi()
// auth 
const signOutOfApp = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    authInstance.signOut((error, data, response) => {
        if (error) {
            console.error(error);
            
        }
    });
}

// barrels 
const getBarrels = (that, perPage, pageNo) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const opts = {
        'page': that.pageNo,
        'perPage': that.barrelSelect,
        'allItems': false
    }
    barrelInstance.readBarrels(that.$route.params.id, opts, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`There was an error: ${error}`)
        } else {
            that.barrels = data.items
            that.allPages = data.pages
        }
    })
}

const getActionsFromBarrel = (that) => {
    barrelInstance.readActionsByBarrel(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`Something went wrong: ${error}`)
        } else {
            that.actions = data.items
        }
    })
}

const readBarrel = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelInstance.readBarrel(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`Something went wrong getting barrel: ${error}`)
        } else {
            that.barrel = data
            that.loading = false
        }
    })
}

const createBarrel = (that) => {
    that.companyId = that.$route.params.id
    // let barrelPost = new RickhouseApi.BarrelPost(that.eCode, that.filledAt, that.proof, that.gallons, that.pounds, that.notes, that.mashbillId, that.barrelTypeId) // BarrelPost | 
    const barrelPost = {
        "proof": +that.proof,
        "pounds": +that.pounds,
        "filledAt": that.filledAt,
        "mashbillId": that.mashbillId,
        "barrelTypeId": that.barrelTypeId,
        "externalCode": that.eCode,
        "gallons": +that.gallons,
        "notes": that.notes
    }
    barrelInstance.createBarrel(that.$route.params.id, barrelPost, (error, data, response) => {
        if (error) {
          toast.error(`Problem creating barrel: ${error}`)
        } else {
          toast.success('Congrats! You created a barrel')
          that.proof = that.gallons = that.pounds = 0
          that.filledAt = that.eCode = that.notes = that.barrelTypeId = that.mashbillId = ''
          that.newBarrelId = data.id
        }
    })
}

const updateBarrel = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let barrelPut = new RickhouseApi.BarrelPut(+that.proof, +that.gallons, +that.pounds, that.notes) // BarrelPut 
    barrelInstance.updateBarrel(that.$route.params.id, barrelPut, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`Something went wrong: ${error}`)
        } else {
          toast.success("Congratulations! You updated the barrel")
        }
      })
}

const updateBarrelStatus = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const barrelStatusPut = new RickhouseApi.BarrelStatusPut(that.status)
    barrelInstance.updateBarrelStatus(that.$route.params.id, barrelStatusPut, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`Something went wrong changing status: ${error}`)
        } else {
            toast.success('Barrel status successfully updated')
            that.$el.querySelector('#closeStatus').click()
        }
    });
}

const getInitialBarrelFilter = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let opts = {
        'page': 1,
        'perPage': 25,
        'allItems': false
    }
    barrelInstance.readBarrelsByFilter({
        "companyIds": [],
        "mashbillIds": [],
        "barrelTypeIds": [],
        "rickIds": [],
        "filledAts": []
    }, opts, (error, data, response) => {
        if (error) {
            console.error(error);
            toast.error(`An Error occurred: ${error}`)
        } else {
            that.barrelList = data.items
            that.loading = false
            const key = 'id'
            that.allPages = data.pages

            that.mashIds = [...new Map(data.items.map(item => [item.mashbill[key], item])).values()]
            that.barrelTypeIds = [...new Map(data.items.map(item => [item.barrelType[key], item])).values()]
        }
    })
}

const readBarrelFilterBegin = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    that.loading = true
    const opts = {
        'page': 1,
        'perPage': that.perPage,
        'allItems': false
    }

    barrelInstance.readBarrelsByFilter({
        "companyIds": that.companySelected !== "" ? [that.companySelected] : [],
        "mashbillIds": that.mashbillSelected !== "" ? [that.mashbillSelected] : [],
        "barrelTypeIds": that.barrelTypeSelected !== "" ? [that.barrelTypeSelected] : [],
        "rickIds": [],
        "filledAts": that.toDate !== "" ? [{
            "start": that.fromDate,
            "end": that.toDate
        }] : []
    }, opts, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`An error occurred: ${error}`)
        } else {
            that.loading = false
            that.allPages = data.pages
            that.barrelList = data.items
        }
    })
}

const readBarrelFilter = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    that.loading = true
    const opts = {
        'page': that.pageNo,
        'perPage': that.perPage,
        'allItems': false
    }

    barrelInstance.readBarrelsByFilter({
        "companyIds": that.companySelected !== "" ? [that.companySelected] : [],
        "mashbillIds": that.mashbillSelected !== "" ? [that.mashbillSelected] : [],
        "barrelTypeIds": that.barrelTypeSelected !== "" ? [that.barrelTypeSelected] : [],
        "rickIds": [],
        "filledAts": that.toDate !== "" ? [{
            "start": that.fromDate,
            "end": that.toDate
        }] : []
    }, opts, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`An error occurred: ${error}`)
        } else {
            that.loading = false
            that.allPages = data.pages
            that.barrelList = data.items
        }
    })
}

const getBarrelLabel = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelInstance.readLabelByBarrel(that.$route.params.id, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
          that.barrelLabel = data.labelContent
        }
      });
}

// rickhouse methods
const readRickhouses = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    rickhouseInstance.readRickhouses(null, (error, data, response) => {
        if (error) {
            console.error(error);
        } else {
            that.rickhouses = data.items;
            that.loading = false
        }
    })
}

const createNewRickhouse = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickhousePost = new RickhouseApi.RickhousePost(that.rName, that.rCode);
    rickhouseInstance.createRickhouse(rickhousePost, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`An error occurred: ${error}`)
        } else {
            that.createRickDialog = false
            toast.success('Congrats! Successfully created rickhouse')
        }
    });
}

const getRickhouseById = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    rickhouseInstance.readRickhouse(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.rickhouse = data
            that.loading = false
        }
    });
}

const updateRickhouse = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickhousePut = new RickhouseApi.RickhousePut(that.rName)
    rickhouseInstance.updateRickhouse(that.$route.params.id, rickhousePut, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`An error occurred updating rickhouse`)
        } else {
            toast.success('Successfully updated rickhouse')
            that.$el.querySelector('#closeUpdateModal').click()
        }
    });
}

const removeRickhouse = (that) => {
    rickhouseInstance.deleteRickhouse(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.deleteRickhouse = false
            that.$router.push({
                name: 'Rickhouses'
            })
        }
    })
}

// mashbills methods
const getMashBills = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    mashBillsInstance.readMashbills(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.mashbills = data.items
        }
    })
}

const readMashBill = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    mashBillsInstance.readMashbill(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.mashbill.push(data)
            that.loading = false
        }
    });
}

const readBarrelByMashBill = (that, perPage, pageNo) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    that.loading = true
    const opts = {
        'page': that.pageNo,
        'perPage': that.perPage,
        'allItems': false
    }
    mashBillsInstance.readBarrelsByMashbill(that.$route.params.id, opts, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
            that.loading = false
            that.allPages = data.pages
            that.barrels = data.items
        }
    });
}

const editMashBill = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const mashbillPut = new RickhouseApi.MashbillPut(that.mashbillCode, that.mashbillName) // MashbillPut | 
    mashBillsInstance.updateMashbill(that.$route.params.id, mashbillPut, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`Problem editing mashbill: ${error}`)
        } else {
            toast.success('Successfully edited mashbill')
        }
      });
}

const createNewMashBill= (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const mashbillPost = new RickhouseApi.MashbillPost(that.mashbillName, that.mashbillCode.toUpperCase(), that.mashbillECode.toUpperCase())
    mashBillsInstance.createMashbill(that.$route.params.id, mashbillPost, (error, data, response) => {
        if (error) {
          console.error(error);
          toast.error(`An error occurred: ${error}`)
        } else {
          toast.success('Congrats a new mashbilll was created')
        }
    });
}

const getMashBillActions = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let opts = {
        'page': 1, // Number | 
        'perPage': new RickhouseApi.PerPage(), // PerPage | 
        'allItems': false // Boolean | 
      }
      mashBillsInstance.readActionsByMashbill(that.$route.params.id, null, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
            that.mashbillActions = data.items
        }
      })
}

// ricks methods
let opts = {
    'page': 1,
    'perPage': new RickhouseApi.PerPage(),
    'allItems': false
}

const readBarrelsByRick = (that, perPage, pageNo) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const opts = {
        'page': pageNo,
        'perPage': perPage,
        'allItems': false
    }
    ricksInstance.readBarrelsByRick(that.$route.params.id, opts, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.barrels = data.items;
            that.loading = false;
        }
    });
}

const getRickFromCode = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickCode = that.rickCode
    ricksInstance.readRickByCode(rickCode, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
          that.rickId = data.id
        }
      })
}

const readRick = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    ricksInstance.readRick(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.rick = data
            that.loading = false
            that.reconcilationStatus = data.reconciliationStatus
            getReconciliations(that)
        }
    });
}

const getRicksByRickhouseId = (that, perPage, pageNo) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const opts = {
        'page': pageNo,
        'perPage': perPage,
        'allItems': false
    }
    ricksInstance.readRicks(that.$route.params.id, opts, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.ricks = data.items;
        }
    });
}

const createRickByRickhouseId = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickPropertiesIn = new RickhouseApi.RickPropertiesIn(that.floor, that.row, that.level)
    const rickPost = new RickhouseApi.RickPost(that.rickName, that.barrelCapacity, rickPropertiesIn,that.rickCode)
    // const rickPost = new RickhouseApi.RickPost(that.rickName, that.barrelCapacity, {"floor": that.floor, "row": that.row, "level": that.level} ,that.rickCode);
    ricksInstance.createRick(that.$route.params.id, rickPost, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`Something went wrong. Error: ${error}`)
        } else {
            toast.success('New rick was created successfully')
            that.$el.querySelector('.btn-close').click()
        }
    });
}

const getRickActions = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    ricksInstance.readActionsByRick(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.rickActions = data.items;
        }
    });
}

const getRicksLabel = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    ricksInstance.readLabelByRick(that.$route.params.id, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
          that.rickLabel = data.labelContent
        }
      });
}

const removeRick = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    ricksInstance.deleteRick(that.$route.params.id, (error, data, response) => {
        if (error) {
          console.error(error)
        } else {
          that.$el.querySelector('.btn-close').click()  
          toast.success("Deleted rick successfully")
          that.$router.push({name: 'RickhouseOverview', params: {id: that.rick.rickhouse.id}})
        }
      })
}
// companies 
const getCompany = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    companyInstance.readCompany(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.company = data;
            that.loading = false;
        }
    });
}

const getCompanyForFilter = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    companyInstance.readCompanies(null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.companyIds = data.items
        }
    })
}

const getCompanies = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    companyInstance.readCompanies(null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.companies = data.items
            that.loading = false
        }
    })
}

const editCompany = (that) => {
    let companyPut = new RickhouseApi.CompanyPut(that.cCode, that.cName)
    companyInstance.updateCompany(that.$route.params.id, companyPut, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`An error occurred editing: ${error}`)
        } else {
            that.showCompanyPutDialog = false
            location.reload()
        }
    });
}

const createNewCompany = (that) => {
    let companyPost = new RickhouseApi.CompanyPost(that.companyName, that.companyCode)
    companyInstance.createCompany(companyPost, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`There was an error creting a company: ${error}`)
        } else {
            that.displayCompany = false;
            toast.success('New company successfully created')
            that.$el.querySelector('.btn-close').click()
        }
    })
}

const getCompanyActions = (that) => {
    companyInstance.readActionsByCompany(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.companyActions = data.items
        }
    })
}
// barrel types
const getBarrelTypes = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelTypeInstance.readBarrelTypes(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.barrelTypes = data.items
        }
    });
}

const getBarrelTypeById = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelTypeInstance.readBarrelType(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
            return;
        } else {
            that.barrelType = data
            that.loading = false
        }
    });
}

const getBarrelsByBarrelType = (that, perPage, pageNo) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    that.loading = true
    const opts = {
        'page': that.pageNo,
        'perPage':that.perPage,
        'allItems': false
    }
    barrelTypeInstance.readBarrelsByBarrelType(that.$route.params.id, opts, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.loading = false
            that.barrels = data.items
            that.allPages = data.pages
        }
    })
}

const getBarrelTypesActions = (that) => {
    barrelTypeInstance.readActionsByBarrelType(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.barrelTypesActions = data.items
        }
    });
}

const createNewBarrelType = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let barrelTypePost = new RickhouseApi.BarrelTypePost(that.bTypeName, "new", that.bTypeCode)
    barrelTypeInstance.createBarrelType(that.$route.params.id, barrelTypePost, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`There was an error reading ${error}`)
        } else {
          toast.success('New barrel type created')
        }
    });
}

const updateBarrelType = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let barrelTypePut = new RickhouseApi.BarrelTypePut(that.barrelTypeName, that.barrelTypeStatus) 
    barrelTypeInstance.updateBarrelType(that.$route.params.id, barrelTypePut, (error, data, response) => {
        if (error) {
          console.error(error)
          if (that.barrelTypeName == '' || that.barrelTypeStatus == '') that.barrelTypeErrorMsg = "All fields must be filled"
          else {
            toast.error(`Error Occurred: ${error}`, {
                timeout: 5000,
            })
        }
        } else {
          toast.success('Congratulations! Barrel type successfully updated')
          that.$el.querySelector('#updatebarrelTModadl').click()
        }
      });
}

// users
const getUsers = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    userInstance.readUsers(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
            return;
        } else {
            that.users = data.items
        }
    })
}

const createUser = (that, permissions) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let userPost = new RickhouseApi.UserPost(that.userCode, that.userCode, that.endpoints, permissions,  that.password); // UserPost | 
    userInstance.createUser(that.$route.params.id, userPost, (error, data, response) => {
        if (error) {
            toast.error(`Error occured: ${error}`)
        } 
        else {
            toast.success('User successfully created')
        }
      })
}

const getUserById = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    userInstance.readUser(that.$route.params.id, (error, data, response) => {
        if (error) {
            console.error(error)
            return;
        } else {
            that.user = data
            that.loading = false
        }
    });
}

const createNewUser = (that) => {
    const userPost = new RickhouseApi.UserPost(this.userCode, this.userName)
    userInstance.createUser(that.$route.params.id, userPost, (error, data, response) => {
        if (error) {
            console.error(error);
            toast.error(`An error occurred creating new user: ${data}`)
        } else {
            that.users = data.items;
            that.displayUserDialog = false
            toast.success('Successfully created new user')
        }
    });
}

const getUserActions = (that) => {
    userInstance.readActionsByUser(that.$route.params.id, null, (error, data, response) => {
        if (error) {
            console.error(error)
        } else {
            that.userActions = data.items
        }
    });
}

const updateUser = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken;
    let userPut = new RickhouseApi.UserPut(that.userName, that.userName, [], that.permissions, that.userPassword)  

    userInstance.updateUser(that.$route.params.id, userPut, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`Problem updating user ${error}`)
        } else {
          toast.success('Successfully updated user')
        }
      });
}

// user measurement
const getMeasurementDevice = () => {
    HTTPBearer.accessToken = createStore.state.accessToken
    userMeasuremntInstance.readDeviceMeasurements(null, (error, data, response) => {
        if (error) {
            console.error(error)
            return;
        } else {
            createStore.mutations.getMeasurements(data)
        }
    });
}

// qr codes 
const getBarrelFromSelect = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
        const barrelCode = that.text
        barrelInstance.readBarrelByCode(barrelCode, (error, data, response) => {
            if (error) {
                console.log('error in barrel code',that.text)
                toast.error(`There was an error reading barrel: ${error}`)
                that.$router.push({name: 'Problems'})
                // that.$router.push({ name: 'Error' })
            } else {
                that.barrelId = data.id
                toast.success('Scanned Barrel Successfully')
                if (that.$route.name == 'Select') that.$router.push({ name: 'ReadBarrel', params: { id: data.id } })
                else {
                    // createStore.mutations.updateBarrels.updateBarrels(data.id)
                    store.commit('updateBarrels', data.id)
                    store.commit('getBarrelCode', data.code)
                    that.text = ''
                }
            }
        })
  }

  const getRickFromSelect = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickCode = that.text
        ricksInstance.readRickByCode(rickCode, (error, data, response) => {
        if (error) {
            console.error(that.text)
            toast.error(`Error reading rick: ${error}`)
            this.$router.push({name: 'Problems'})
            // that.$router.push({ name: 'Error' })
        } else {
            that.rickId = data.id
            toast.success('Rick Scanned Successfully')
            that.$router.push({ name: 'ReadRick', params: { id: data.id } })
        }
        })
  }
  
  const getBarrelsFromRick = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickId = that.$route.params.id
    ricksInstance.readBarrelsByRick(rickId, null, (error, data, response) => {
      if (error) {
        console.error(error)
      } else {
        that.barrels = data.items
        that.loading = false
      }
    })
  }

  const getBarrelById = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelInstance.readBarrel(that.$route.params.id, (error, data, response) => {
      if (error) {
        that.errMsg = error
        console.error(error)
      } else {
        that.barrel = data
        that.loading = false
      }
    })
  }
  
  const getActionsBarrels = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    barrelInstance.readActionsByBarrel(that.$route.params.id, null, (error, data, response) => {
      if (error) {
        console.error(error)
      } else {
        that.actions = data.items
      }
    })
  }
  
  const unloadBarrelByid = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const barrelId = that.$route.params.id
    const removeId = null
    const barrelRickPut = new RickhouseApi.BarrelRickPut(removeId)
    barrelInstance.updateBarrelRick(barrelId, barrelRickPut, (error, data, response) => {
      if (error) {
        console.error(error)
      } else {
        if (that.$route.name == 'SingleBarrel') {
            toast.success('Successfully unloaded barrel')
            that.$el.querSelector('#unloadBarrelModal').click()
        }
        else that.$router.push({ name: 'Confirmation' })
      }
    })
  }

  const loadBarrel = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const barrelId = that.$route.params.id

  }

  const updateBarrelsRick = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const barrelId = that.$route.params.id
    const rickId = that.rickId
    barrelInstance.updateBarrelRick(barrelId, { rickId }, (error, data, response) => {
      if (error) {
        console.error(error)
        that.$router.push({ name: 'Error', parmas: { errMsg: error } })
      } else {
        if (that.$route.name == 'SingleBarrel') {
             toast.success('Congratulations! Barrel successfully loaded into rick') 
             that.$el.querySelector('#confirmRickModal').click()
        }
        else that.$router.push({ name: 'Confirmation' })
      }
    })
  }
  
  const getRickByCode = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickCode = that.text
    ricksInstance.readRickByCode(rickCode, (error, data, response) => {
      if (error) {
        toast.error(`Problem scanning the rick: ${error}`)
        console.error(error)
      } else {
        toast.success('Rick properly scanned')
        that.rickId = data.id
      }
    })
  }
  
  const getActionsFromRicks = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    const rickId = that.$route.params.id
    ricksInstance.readActionsByRick(rickId, null, (error, data, response) => {
      if (error) {
        console.error(error)
      } else {
        that.rickActions = data.items
        that.disableButtonStatus = false
      }
    })
  }
  
// reconciliations
  const getReconciliations = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    reconciliationInstance.readReconciliations(that.$route.params.id, null, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          that.reconciliations = data.items
        }
      });
  }

  const getReconciliationsById = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    reconciliationInstance.readReconciliation(that.$route.params.id, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          that.reconciliation = data
          that.loading = false
        }
      });
  }

  const getReconciliationActions = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let opts = {
        'page': 1, 
        'perPage': new RickhouseApi.PerPage(),
        'allItems': false 
      };
    reconciliationInstance.readActionsByReconciliation(that.$route.params.id, null, (error, data, response) => {
        if (error) {
          console.log('params',that.$route.params.id)
          console.error(error)
        } else {
          that.rActions = data.items
        }
      })
  }

  const postReconciliation = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    reconciliationInstance.createReconciliation(that.$route.params.id, (error, data, response) => {
        if (error) {
          toast.error(`Error starting reconciliation: ${error}`)
        } else {
          toast.success("Success starting reconciliation")
          readRick(that)
        }
      })
  }

  const removeReconciliation = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    reconciliationInstance.deleteReconciliation(that.reconciliations[that.reconciliations.length - 1].id, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`There was an error removing reconciliation ${error}`)
        } else {
          toast.success('Removed reconciliation')
          readRick(that)
        }
      })
  }

  const updateBarrelReconciliation = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    let reconciliationPut = new RickhouseApi.ReconciliationPut(store.state.recBarrels) 
    reconciliationInstance.updateReconciliation(that.$route.params.id, reconciliationPut, (error, data, response) => {
        if (error) {
            console.error(error)
            toast.error(`Error ni updating reconciliation: ${error}`)
        } else {
            toast.success(`Congratulations! Barrels succcessfully updadted`)
            console.log('API called successfully. Returned data: ' + data)
        }
    })
  }

  const updateRecStatus = (that) => {
    HTTPBearer.accessToken = createStore.state.accessToken
    reconciliationInstance.updateReconciliationStatus(that.$route.params.id, (error, data, response) => {
        if (error) {
          console.error(error)
          toast.error(`Updating status error: ${error}`)
        } else {
        //   store.commit('endBarrels')
          toast.success('Reconciliation has been updated')
        }
      })
  }

export {
    getRickhouseById,
    updateRickhouse,
    removeRickhouse,
    getRicksByRickhouseId,
    createRickByRickhouseId,
    getCompany,
    getCompanies,
    editCompany,
    getCompanyActions,
    createNewCompany,
    getBarrels,
    createBarrel,
    loadBarrel,
    getInitialBarrelFilter,
    getBarrelLabel,
    getBarrelTypes,
    getBarrelTypeById,
    getBarrelsByBarrelType,
    updateBarrelType,
    getBarrelTypesActions,
    createNewBarrelType,
    readBarrelFilter,
    readBarrelFilterBegin,
    getUserById,
    createNewUser,
    getUsers,
    createUser,
    getUserActions,
    updateUser,
    getMeasurementDevice,
    getActionsFromBarrel,
    readBarrel,
    updateBarrelStatus,
    updateBarrel,
    readRickhouses,
    createNewRickhouse,
    readBarrelsByRick,
    readRick,
    removeRick,
    getRickFromCode,
    getRicksLabel,
    getRickActions,
    signOutOfApp,
    getCompanyForFilter,
    getMashBills,
    readMashBill,
    readBarrelByMashBill,
    createNewMashBill,
    editMashBill,
    getMashBillActions,
    getBarrelFromSelect,
    getRickFromSelect,
    getBarrelsFromRick,
    getBarrelById,
    getActionsBarrels,
    unloadBarrelByid,
    updateBarrelsRick,
    getRickByCode,
    getActionsFromRicks,
    getReconciliations,
    getReconciliationsById,
    getReconciliationActions,
    postReconciliation,
    removeReconciliation,
    updateBarrelReconciliation,
    updateRecStatus
}