<template>
    <tr :style="inputCheckBox == true ? 'background-color: #4169E1; color: #fff; border: 1px solid #6693F5' : ''">
        <td class="check-cell">
            <input
              v-model="inputCheckBox"
              @change="addBarrelToList"
              class="center-check"
              ref="barrelListSelect"
              type="checkbox"
            />
        </td>     
        <td class="table-data">
            <router-link :to="{ name: 'SingleBarrel', params: { id: barrel.id } }" :class="inputCheckBox == true ? 'blue-link' : 'primary-text'">
            {{ barrel.code }}
            </router-link>
        </td>
        <td class="d-sm-none d-md-table-cell d-none d-sm-table-cell">{{ barrel.mashbill.code }}</td>
        <td class="d-sm-none d-md-table-cell d-none d-sm-table-cell">{{ barrel.barrelType.code }}</td>
        <td class="d-sm-none d-md-table-cell d-none d-sm-table-cell">{{ barrel.status }}</td>
    </tr>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
    name: 'BarrelFilterRow',
    props: ['barrel'],
    data() {
        return {
            checkSelectValue: null,
            inputCheckBox: false
        }
    },
    computed: {
        ...mapState(['cBoxStatus']),
    },
    methods: {
        ...mapState(['barrelListSelection']),
        ...mapMutations(['addBarrelToSelect']),
        addBarrelToList() {
            this.$nextTick(() => {
                if (this.$refs.barrelListSelect.checked == true) {
                    this.addBarrelToSelect(this.barrel)          
                 }
            })
            

            if(this.$refs.barrelListSelect.checked == false) {
                const barrelIndex = this.$store.state.barrelListSelection.map(item => {
                    return item.id
                }).indexOf(this.barrel.id)
                this.$store.state.barrelListSelection.splice(barrelIndex, 1)
            }
        }
    },
    watch: {
        ...mapState(['cBoxStatus']),
        ...mapMutations(['addBarrelToSelect']),
        cBoxStatus() {
            if(this.cBoxStatus) {
                this.inputCheckBox = true
                this.addBarrelToSelect(this.barrel)
            }
            else if(!this.cBoxStatus) {
                this.inputCheckBox = false
            }
        }
    }
}
</script>

<style scoped>
tr td {
    padding: 12px;
}

.check-cell {
    text-align: center;
    vertical-align: middle;
}

tr:nth-child(odd) {
    background-color: #F5F5F5;
}

.blue-link, .blue-link:hover {
    color: white;
}

@media only screen and (max-width: 600px)  {
    .table-data {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 24px;
    }
}
</style>>
