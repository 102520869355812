<template>
  <div class="container-fluid none">
    <div class="row">
      <div class="col-md-1 col-1">
        <new-side-bar />
      </div>
      <div class="col-md-10 col-10">
        <main>
          <router-view/>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import NewSideBar from './components/common/NewSideBar.vue'

export default {
  components: {
    NewSideBar
  },
  methods: {
    ...mapActions([
      'featchAccessToken'
    ])
  },
  created() {
    this.featchAccessToken();
  }
}
</script>
<style>
.none {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-top: 0;
}
</style>
