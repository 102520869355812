<template>
    <div class="row">
        <div class="col-md-10 mt-4">
            <h2 class="text-center small-caps">Create Barrel</h2>
            <div class="col-md-6 mx-auto">
                <p class="red">{{errMsg}}</p>
                <div class="form-group">
                    <label for="eCode" class="form-label">External Code</label>
                    <input 
                        type="text" 
                        v-model="eCode" 
                        id="eCode" 
                        class="form-control"
                    />
                </div>
                <div class="form-group mt-2">
                    <label for="proof" class="form-label">Proof</label>
                    <input 
                        type="number" 
                        step="0.01" 
                        id="proof" 
                        v-model="proof" 
                        class="form-control"
                    />
                </div>
                <div class="form-group mt-2">
                    <label for="pounds" class="form-label">Pounds</label>
                    <input 
                        type="number"
                        id="pounds"
                        class="form-control"
                        v-model="pounds"
                        step="0.01"
                    >
                </div>
                <div class="form-group mt-2">
                    <label for="gallons" class="form-label">Gallons</label>
                    <input  
                        class="form-control"
                        v-model="gallons"
                        id="gallons"
                        type="number"
                        step="0.01"
                    />
                </div>
                <div class="form-group mt-2">
                    <label for="notes">Notes</label>
                    <textarea 
                        id="notes" 
                        class="form-control" 
                        v-model="notes"
                    ></textarea>
                </div>
                <div class="form-group mt-2 mb-2">
                    <label for="filterdAt" class="form-label">Filterd At: </label>
                    <flat-pickr 
                        v-model="filledAt"
                        class="flat-picker form-control"
                        placeholder="YYYY-MM-DD"
                        id="filledAt"
                    />
                </div>
                <div class="form-group mt-2">
                    <label for="mashbillId" class="form-label">Mashbill ID</label>
                    <select class="form-control" id="mashbillId" v-model="mashbillId" v-if="mashbills.length">
                        <option
                            v-for="mashbill in mashbills"
                            :key="mashbill.id"
                            :label="mashbill.code"
                            :value="mashbill.id"
                        />
                    </select>
                    <p v-else class="red">A mashbill needs to be created</p>
                </div>
                <div class="form-group mt-2">
                    <label for="barrelTypeId" class="form-label">Barrel Type ID</label>
                    <select class="form-control" id="barrelTypeId" v-model="barrelTypeId" v-if="barrelTypes.length">
                        <option 
                            v-for="barrelType in barrelTypes"
                            :key="barrelType.id"
                            :label="barrelType.code"
                            :value="barrelType.id"
                        />
                    </select>
                    <p class="red" v-else>A barrel type needs to be created </p>
                </div>
                <p class="text-end red mt-2">*all fields required</p>
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <button class="btn btn-outline-primary" @click="createBarrel">
                            Create Barrel
                        </button>
                    </div>
                </div>
                <div class="row mt-4" v-if="newBarrelId !== ''">
                    <div class="col-12 text-center">
                        <router-link :to="{ name: 'SingleBarrel', params: { id: newBarrelId } }">
                            <button 
                                class="btn btn-outline-secondary circle-button"
                                v-tippy="{content: 'Go to New Barrel'}"
                            >
                                <i class="fa-solid fa-person-walking"></i>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { 
    createBarrel,
    getBarrelTypes,
    getMashBills
 } from '@/utils/auth'

export default {
    name: 'CreateBarrel',
    data() {
        return {
            eCode: '',
            proof: 0,
            pounds: 0,
            gallons: 0,
            filledAt: '',
            notes: '',
            mashbillId: '',
            barrelTypeId: '',
            barrelTypes: [],
            mashbills: [],
            errMsg: '',
            newBarrelId: ''
        }
    },
    components: {
        flatPickr
    },
    methods: {
        createBarrel() {
            if (this.eCode == '' || this.proof == '' || this.proof == 0 || this.pounds == 0 || this.pounds == '' || this.gallons == 0 || this.gallons == '' || this.filterdAt == '' || this.mashbillId == '' || this.barrelTypeId == '') this.errMsg = 'all fields must be enterd'
            else createBarrel(this)
        }
    },
    beforeMount() {
        getBarrelTypes(this)
        getMashBills(this)
    }
}
</script>