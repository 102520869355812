<template>
  <div>
    <sign-in-form />
  </div>
</template>

<script>
import SignInForm from '../components/SignInForm.vue'
const RickhouseApi = require('rickhouse_api');

export default {
  name: 'Home',
  components: {
    SignInForm
  },

}
</script>
