<template>
    <div class="row mt-5 mt-md-2">
        <div class="col-12" v-if="!loading">
            <h2 class="text-center">Barrel Found in: {{ barrel.rick.code }}</h2>
            <div class="video-container">
                <div id="qr-code-region"></div>
            </div>
            <input type="text" v-model="text" class="input-row" placeholder="Rick ID" min="12" />
            <div class="btn-container">
                <button 
                    class="action-button margin-bot" 
                >
                    Confirm Rick
                </button>
                <button 
                    class="action-button margin-bot"
                >
                    Change rick
                </button>
            </div>
        </div>
        <div class="col-12" v-else>
            <app-loader />
        </div>
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue'
import { getBarrelById } from '@/utils/auth'
import { Html5Qrcode } from 'html5-qrcode'

export default {
    name: 'UnloadBarrel',
    data() {
        return {
            barrel: null,
            loading: true,
            text: ''
        }
    },
    components: {
        AppLoader
    },
    methods: {
        onScanSuccess(decodedText, decodedResult) {
            this.$emit('result', decodedText)
            this.text = decodedText
        }
    },
    beforeMount() {
        getBarrelById(this)
    },
    updated() {
        const config = {
            fps: this.fps,
            aspectRatio: 0.688,
            qrbox: this.qrbox
        }
        const html5Qrcode = new Html5Qrcode('qr-code-region')
        html5Qrcode
            .start({ facingMode: 'environment' }, config, this.onScanSuccess)
            .catch((err) => {
                console.error(err)
            })
    }
}
</script>

<style scoped>
.video-container {
    border: 1px solid black;
    border-radius: 2px;
    height: 200px;
    width: 100%;
    background-color: white;
    margin-top: 24px;
}

.input-row {
    margin-top: 16px;
}

.btn-container {
    margin-top: 20px;
}

.btn-container>button {
    margin-left: 0;
    margin-bottom: 16px;
}

.hidden {
    display: none;
}
</style>