<template>
    <div v-if="!loading" class="container-wrapper mt-5 mt-md-2">
        <div class="col-12 button-container box">
            <h2 class="text-center small-caps">Rick: {{ rick.code }} </h2>
            <div class="ms-2 d-block">
                <p><strong>Name:</strong> {{rick.name}}</p>
                <p><strong>Count / Capacity: </strong>{{rick.barrelCount}} / {{rick.barrelCapacity}}</p>
                <p><strong>Rickhouse: </strong>{{rick.rickhouse.code}}</p>
            </div>
            <h3 class="text-center small-caps">Barrels</h3>
            <table class="table table-striped mb-4">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Position</th>
                    </tr>   
                </thead>
                <tbody>
                    <barrel-list-status v-for="barrel in barrels" :barrel="barrel" :key="barrel.id" />
                </tbody>
            </table>
            <router-link 
                    :to="{name: 'RecSelect', params: {id: rick.id}}"
                    v-if="rick.reconciliationStatus && rick.reconciliationStatus == 'created' || rick.reconciliationStatus && rick.reconciliationStatus == 'started'"
            >
                <button 
                    class="btn btn-outline-primary text-center mb-4 w-100"
                >
                Go To Reconciliation
                </button>
            </router-link>
        </div>          
    </div>
    <div class="row" v-if="loading">
        <app-loader></app-loader>
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue'
import BarrelListStatus from '@/components/barrels/BarrelListStatus.vue'
import { getBarrelsFromRick , readRick} from '@/utils/auth'

export default {
    name: 'ReadRick',
    data () {
        return {
            loading: true,
            barrels: null,
            rick: null
        }
    },
    components: {
        AppLoader,
        BarrelListStatus
    },
    beforeMount () {
        readRick(this)
        getBarrelsFromRick(this)
    }
}
</script>

<style scoped>


</style>>
