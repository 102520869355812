import { createStore } from 'vuex'
import {signOutOfApp} from '@/utils/auth';

export default createStore({
  state: {
    userLoggedIn: false,
    accessToken: null,
    measurementDevice: '',
    companyId: null,
    cBoxStatus: false,
    barrelListSelection: [],
    barrels: [],
    recBarrels: [],
    rBarrelCode: []
  },
  mutations: {
    authStatus: (state) => {
      state.userLoggedIn = !state.userLoggedIn
    },
    addToken: (state, accessToken) => {
      state.accessToken = accessToken
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
      state.userLoggedIn = Boolean(state.accessToken)
    },
    getCompanyId: (state, companyId) => {
      state.companyId = companyId
    },
    getMeasurements: (state, payload) => {
      state.measurementDevice = payload
    },
    setSelectCheck: (state, payload) => {
      state.cBoxStatus = payload
    },
    addBarrelToSelect: (state, barrel) => {
      state.barrelListSelection.push(barrel)
    },
    destroyToken: (state, payload) => {
      state.accessToken = null;
      state.userLoggedIn = false;
      localStorage.removeItem('accessKey')
    },
    updateBarrels: (state, barrels) => {
      state.barrels.push(barrels)
      state.recBarrels = [...new Set(state.barrels)]
    },
    getBarrelCode: (state, rBarrelCode) => {
      state.rBarrelCode.push(rBarrelCode)
    },
    endBarrels: (state, barrels) => {
      state.barrels = []
      state.recBarrels = []
    }
  },
  actions: {
    login({commit}, payload) {
      commit('authStatus')
      commit('addToken', payload)
      localStorage.setItem('accessKey', payload)
    },
    featchAccessToken({commit}) {
      commit('updateAccessToken', localStorage.getItem('accessKey'))
    },
    checkBoxStatus({commit}, payload) {
      commit('setSelectCheck', payload)
    },
    async signOut({commit}) {
      await signOutOfApp()
      commit('destroyToken')
    },
    updateReconciliation({commit}) {
      commit('updateBarrels')
    }
  },
  modules: {
  }
})


