<template>
    <div class="row mt-5 mt-md-2" v-if="!loading">
        <div class="col-md-10 offset-md-1">
            <div class="box">
                <h2 class="text-center">
                    Barrel Type: {{ barrelType.name }}
                </h2>
                <p class="text-center">Status: {{ barrelType.status }}</p>
                <div v-if="barrels.length !== 0">
                    <h3 class="text-center text-caps">Barrels</h3>
                    <single-barrel-list-item v-for="barrel in barrels" :key="barrel.id" :barrel="barrel" />
                    <div class="row mb-2">
                        <div class="col-md-2 offset-md-1">
                            <select class="form-select" v-model="perPage" @change="handleSizeChange">
                                <option disabled>--select page size--</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div> 
                        <div class="col-md-8">
                            <ul class="pagination">
                                <li 
                                    class="page-item" 
                                    v-for="idx in allPages" 
                                    :key="idx" 
                                    ref="pagList"
                                >
                                    <input 
                                        class="page-link" 
                                        type="button"
                                        :value="idx"  
                                        @click.prevent="getPagValue(idx)"                
                                    />
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>
                <div class="text-center mt-4 mb-5" v-else>
                    <i class="fa-solid fa-bottle-droplet fa-3x red-text"></i>
                    <h4>No barrels with this barrel type</h4>
                </div>
                <div class="row">
                    <div class="col text-center d-block">
                        <button 
                            class="btn btn-outline-info circle new-btn" 
                            v-tippy="{ content: 'Update Barrel Type' }"
                            data-bs-toggle="modal"
                            data-bs-target="#updateBarrelType"
                        >
                            <i class="fa-sharp fa-light fa-pen-nib"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <div class="accordian" id="barrelTypeActions">
                            <div class="accordian-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Actions
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse"
                                    data-bs-parent="#barrelTypeActions">
                                    <div class="accordion-body">
                                        <common-actions v-for="action in barrelTypesActions" :key="action.id" :action="action" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updateBarrelType" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Barrel Type</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="updatebarrelTModadl"></button>
                        </div>
                        <div class="modal-body">
                            <p class="red-text">{{barrelTypeErrorMsg}}</p>
                            <div class="form-group">
                                <label for="barrelTyleName" class="form-label">Barrel Type Name</label>
                                <input 
                                    type="text" 
                                    id="barrelTyleName" 
                                    class="form-control" 
                                    v-model="barrelTypeName" 
                                    :placeholder="barrelType.name"
                                />
                                <label for="barrelTypeStatus" class="form-label mt-2">Barrel Type Status</label>
                                <select name="barrelTypeSelect" id="barrelTypeStatus" class="form-select" v-model="barrelTypeStatus">
                                    <option>new</option>
                                    <option>used</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-outline-primary" @click="updateBarrelStatus">Update Barrel Type</button>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import SingleBarrelListItem from '@/components/barrels/SingleBarrelListItem.vue';
import CommonActions from '@/components/actions/CommonActions.vue';
import AppLoader from '@/components/AppLoader.vue'
import { 
        getBarrelTypeById, 
        getBarrelsByBarrelType, 
        getBarrelTypesActions ,
        updateBarrelType
    } from '../utils/auth';
import { mapState } from 'vuex';

export default {
    name: 'SingleBarrelType',
    data() {
        return {
            loading: true,
            barrelType: [],
            barrels: [],
            barrelTypesActions: [],
            perPage: 25,
            allPages: 1,
            pageNo: 1,
            currentPage: 1,
            barrelTypeName: '',
            barrelTypeStatus: '',
            barrelTypeErrorMsg: ''
        }
    },
    methods: {
        handleSizeChange() {
            getBarrelsByBarrelType(this, this.perPage, this.currentPage);
        },
        handleCurrentChange() {
            getBarrelsByBarrelType(this, this.perPage, this.currentPage);
        },
        getPagValue(btnValue) {
            this.pageNo = btnValue
            getBarrelsByBarrelType(this)
        },
        updateBarrelStatus() {
            updateBarrelType(this)  
        }
    },
    computed: {
        ...mapState(['accessToken'])
    },
    components: {
        SingleBarrelListItem,
        CommonActions,
        AppLoader
    },
    beforeMount() {
        getBarrelTypeById(this);
        getBarrelTypesActions(this);
    },
    mounted() {
        getBarrelsByBarrelType(this);
    }
}
</script>

<style scoped>
.red-text {
    color: #FF3131;
}

.round-button {
    border-radius: 50%;
}

.red-text {
    color: #B83A4B;
}

@media only screen and (max-width: 600px) {
    .new-btn {
        width: 40px;
    }
}
</style>