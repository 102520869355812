import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification"

import '@fortawesome/fontawesome-free/js/all.js'
import VueTippy from 'vue-tippy'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import "vue-toastification/dist/index.css"
import 'tippy.js/dist/tippy.css' 
import 'tippy.js/themes/material.css'
import 'tippy.js/animations/scale.css'
import 'flatpickr/dist/themes/material_green.css'

import './main.css'

createApp(App)
    .use(store)
    .use(router)
    .use(VueTippy, {
        defaultProps: { 
            placement: 'bottom' ,
            animation: 'scale',
            arrow: true,
            theme: 'material'
        }
    })
    .use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 20,
        newestOnTop: true,
        timeout: 3000,
        closeOnClick: true
    })
    .mount('#app')
