<template>
    <router-link :to="{name: 'RickhouseOverview', params: {id: rickhouse.id}}">
        <div class="primary-text row single-list">
            <div class="col-md-6 col-6">
                <p class="text-center">{{rickhouse.name}}</p>       
            </div>
            <div class="col-md-6 col-6">
                <p class="text-center">{{rickhouse.updatedAt.toDateString() }}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'RickhouseSingleListItem',
    props: {
        rickhouse: {
            required: true,
            type: Object,
            default: false
        }
    },

}
</script>

<style scoped>

</style>