<template>
    <div class="mb-4">
        <router-link class="primary-text" :to="{name: 'SingleBarrelType', params: {id: barrelType.id}}">
            <div>
                <p>
                    <i class="fa-solid fa-whiskey-glass drink"></i>
                    <span class="ms-3">
                        {{barrelType.code}}
                    </span>
                </p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'CompaniesBarrelTypes',
    props: ['barrelType']
}
</script>

<style scoped>
.drink {
    color: #67C23A;
    height: 20px
}
</style>