<template>
  <div class="loader">
      <div class="loading-icon">
        <i class="fas fa-spinner fa-spin is-loading"></i>
        <h3 class="text-center">Loading...</h3>
      </div>
  </div>  
</template>

<script>

export default {
    name: 'AppLoader'
}
</script>

<style scoped>
.loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.is-loading {
    height: 40px;
    text-align: center;
    margin-left: 40px;
}
</style>