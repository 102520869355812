<template>
    <div class="row">
        <div class="container-fluid">
            <div class="col-12 col-md-6 offset-md-3">
                <div class="video-container">
                    <div id="qr-code-region" style="height: 250px !important"></div>
                </div>
            </div>
        </div> 
        <div class="row input-row">
            <input 
                type="text" 
                class="form-control" 
                id="qr-scan-result" 
                v-model="text"
                placeholder="Barrel Code"
            />
            <button 
                class="btn btn-info mb-2 mt-2 w-100"  
                @click="enterBarrel"
                :disabled="text == ''"
            >
                Add Barrel
            </button>
            <button
                class="btn btn-outline-primary mt-2"
                v-if="$store.state.recBarrels.length"
                @click="updateReconciliation"
            >
                Update Reconciliation
            </button>
            <button
                v-if="$store.state.recBarrels.length"
                class="btn btn-outline-success mt-2"
                data-bs-toggle="modal" 
                data-bs-target="#recModal"
            >
                Check Barrels
            </button>
            <button
                class="btn btn-outline-danger mt-2"
                @click="endReconciliation"
                v-if="$store.state.recBarrels.length"
            >
                End Reconciliation
            </button>
        </div>
        <div class="modal fade" id="recModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Barrels</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h3 class="text-center">Barrel Count: {{$store.state.rBarrelCode.length}}</h3>
                        <p v-for="barrel in $store.state.rBarrelCode" :key="barrel">Barrel Code: {{barrel}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode"
import { useToast } from "vue-toastification"
import { getBarrelFromSelect, updateBarrelReconciliation, updateRecStatus} from '@/utils/auth'

export default {
    name: 'RecSelect',
    data() {
        return {
            text: '',
            config: {
                fps: 2,
                qrbox: {
                    width: 400,
                    height: 200,
                },
                aspectRatio: 1.0,
            },
            html5QrCode: null,
            barrelId: null,
            status: ''
        }
    },
    methods: {
        scanSuccess(decodedText, decodedResult) {
            this.text = decodedText
        },
        enterBarrel() {
            getBarrelFromSelect(this)
        },
        updateReconciliation() {
            updateBarrelReconciliation(this)
        },
        endReconciliation() {
            updateRecStatus(this)
        }
    },
    mounted() {
        this.html5QrCode = new Html5Qrcode("qr-code-region")
        this.html5QrCode
        .start(
            { facingMode: "environment" },
            this.config,
            this.scanSuccess
        ).catch((err) => {
            console.log("in the catch block")
            toast.error(`Error: ${err}`) 
        })
    }
}
</script>

<style scoped>
.video-container {
    height: 250px;
    width: 100%;
    background-color: white;
    margin-top: 36px;
    margin-bottom: 64px;
}

#qr-scan-result {
    text-transform: uppercase;
    display: block;
    margin-top: 24px;
}

.input-row {
    margin-top: 24px;
}
</style>