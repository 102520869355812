<template>
    <div class="row">
        <h3 class="action-title">{{ action.createdAt.toDateString() }} </h3>
    </div>
    <div class="row">
        <div class="col-md-6 col-xs-12">
            <p>Current Age: {{  `${action.payload.ageYearsAndDays[0]} years and ${action.payload.ageYearsAndDays[1]} days` }} </p>
            <p>Proof: {{ action.payload.proof }}</p>
        </div>
        <div class="col-md-6 col-xs-12">
            <p>Status: {{ action.payload.status }} </p>
            <p>Gallons: {{ action.payload.gallons }}</p>
        </div>
    </div>
    <div class="row">
        <p>Notes: {{action.payload.notes}}</p>
    </div>
    <hr />
</template>

<script>
export default {
    name: 'BarrelActions',
    props: ['action']
}
</script>

<style scoped>
.action-title {
    margin-bottom: 8px;
    font-weight: bold;
}

p {
    margin-top: 4px;
    margin-bottom: 4px;
}

</style>