<template>
    <div class="mb-4">
        <router-link class="primary-text" :to="{name: 'SingleBarrel', params: {id: barrel.id}}">
            <p>
                <i class="fa-solid fa-wine-bottle bottle"></i>
                <span class="ms-3">
                    {{barrel.code}}   
                </span>      
            </p>
        </router-link>
    </div>
</template>

<script>

export default {
    name: 'CompaniesBarrels',
    props: ['barrel']
}
</script>

<style scoped>
.bottle {
    color: #67C23A;
    height: 20px;
}
</style>