<template>
    <div class="row">
        <div class="col-md-12">
            <h3>Updated: {{action.payload.updatedAt.toDateString() }}</h3>
            <p>Created: {{action.payload.createdAt.toDateString() }}</p>
            <p>Status: {{action.payload.status}}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RecActions',
    props: ['action']
}
</script>