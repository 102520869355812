<template>
    <div class="row" v-if="loading == false" :key="counterForView">
        <div class="col-md-10 offset-md-1 box">
            <h2 class="text-center mt-4">
                    {{ company.name }}
            </h2>
            <p><strong>Created:</strong> {{ company.createdAt.toDateString() }}</p>
            <p><strong>Last Updated: </strong>{{ company.updatedAt.toDateString() }}</p>
            <ul class="nav mt-4" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a 
                        class="nav-link active" 
                        id="profile-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#barrels" 
                        type="button"
                        role="tab" 
                        aria-controls="profile" 
                    >
                        Barrels
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a 
                        class="nav-link" 
                        id="home-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#users" 
                        type="button"
                        role="tab" 
                        aria-controls="home" 
                    >
                        Users
                    </a>
                </li>               
                <li class="nav-item" role="presentation">
                    <a 
                        class="nav-link" 
                        id="messages-tab" 
                        data-bs-toggle="tab"
                        data-bs-target="#barrel-types" 
                        type="button"
                        role="tab" 
                        aria-controls="messages" 
                    >
                        Barrel Types
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a 
                        class="nav-link" 
                        id="settings-tab" 
                        data-bs-toggle="tab" 
                        data-bs-target="#mashbills" 
                        type="button"
                        role="tab" 
                        aria-controls="settings" 
                    >
                        Mashbills
                    </a>
                </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">       
                <div class="tab-pane active" id="barrels" role="tabpanel" tabindex="0">
                    <companies-barrels :barrel="barrel" :key="key" v-for="(barrel, key) in barrels" />
                    <no-items-found noun="barrels" v-show="!barrels.length" />
                    <div class="row">
                        <div class="col-2 col-md-2 mb-4">
                            <select name="" id="" class="form-select" v-model="barrelSelect" @change="selectBarrelsSize">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div class="col">
                            <button 
                                class="btn btn-outline-secondary small-btn"  
                                @click="decrementPage"
                                :disabled="pageNo == 1"
                            >
                                <i class="fa-solid fa-chevron-left"></i>
                            </button>
                            <span class="ms-2 me-2">
                                {{ pageNo }} / {{ allPages  }}
                            </span>
                            <button 
                                class="btn btn-outline-secondary small-btn"  
                                @click="incrementPage"
                                :disabled="pageNo == allPages"
                            >
                                <i class="fa-solid fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                    <router-link class="primary-text" :to="{name: 'CreateBarrel', params: {id: this.$route.params.id}}">
                        <button class="btn btn-outline-primary circle mb-5" v-tippy="{content: 'Create Barrel'}">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    </router-link>
                </div>
                <div class="tab-pane mb-2" id="users" role="tabpanel" tabindex="0">
                    <companies-users :user="user" :key="key" v-for="(user, key) in users" />
                    <no-items-found noun="users" v-show="!users.length" />
                    <button 
                        class="btn btn-outline-primary circle mb-4" 
                        data-bs-toggle="modal" 
                        data-bs-target="#userModal"
                        v-tippy="{ content: 'Create User' }"
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
                <div class="tab-pane" id="barrel-types" role="tabpanel" tabindex="0">
                    <companies-barrel-types :barrelType="barrelType" :key="key"
                        v-for="(barrelType, key) in barrelTypes" />
                    <no-items-found noun="barrel types" v-show="!barrelTypes.length" />
                    <button 
                        class="btn btn-outline-primary circle mb-4" 
                        data-bs-toggle="modal" 
                        data-bs-target="#barrelTypeModal"
                        v-tippy="{ content: 'Create Barrel Type' }"
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
                <div class="tab-pane" id="mashbills" role="tabpanel" aria-labelledby="settings-tab" tabindex="0">
                    <companies-mashbills :mashbill="mashbill" :key="key" v-for="(mashbill, key) in mashbills" />
                    <no-items-found v-show="!mashbills.length" noun="mashbills" /> 
                    <button 
                        class="btn btn-outline-primary circle mb-4" 
                        data-bs-toggle="modal" 
                        data-bs-target="#mashbillModal" 
                        v-tippy="{ content: 'Create Mashbill' }"
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
            </div>

            <!-- modals -->
            <div class="modal fade" id="userModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add User</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label for="usernameCode" class="form-label">username: </label>
                            <input type="text" class="form-control" id="usernameCode" v-model="userCode" />
                            <label for="passwordCode" class="form-label mt-2">Password</label>
                            <input type="text" class="form-control" id="passwordcode" v-model="password" />
                            <div class="row">
                                <div class="col-6 mt-2">
                                    <div class="form-check d-inline-block">
                                        <input class="form-check-input" type="checkbox" value="" id="createCheck" ref="createP">
                                        <label class="form-check-label" for="createCheck">
                                        Create permissions
                                        </label>
                                    </div>
                                    <div class="form-check d-inline-block mt-2">
                                        <input class="form-check-input" type="checkbox" id="readCheck" ref="readP">
                                        <label class="form-check-label" for="readCheck">
                                        Read permissions
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6 mt-2">
                                    <div class="form-check d-inline-block ml-2">
                                        <input class="form-check-input" type="checkbox" id="updateCheck" ref="updateP">
                                        <label class="form-check-label" for="updateCheck">
                                        Update permissions
                                        </label>
                                    </div>
                                    
                                    <div class="form-check d-inline-block ml-2 mt-2">
                                        <input class="form-check-input" type="checkbox" id="deleteCheck" ref="deleteP">
                                        <label class="form-check-label" for="deleteCheck">
                                        Delete permissions
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-outline-danger" 
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-primary"
                                @click="createUser"
                                data-bs-dismiss="modal"
                            >
                                Save User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="mashbillModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Mashbill</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-2">
                                <label for="mashbillCode" class="form-label">Mashbill Code<span class="red">*</span></label>
                                <input 
                                    type="text" 
                                    v-model="mashbillCode" 
                                    required
                                    max="4" 
                                    placeholder="Mashbill Code"
                                    class="form-control"
                                    id="mashbillCode" 
                                />
                                <span class="regular-text">Max characters 4</span>
                            </div>
                            <div class="mb-2">
                                <label for="mashbillName" class="form-label">Mashbill Name <span class="red">*</span></label>
                                <input 
                                    type="text" 
                                    v-model="mashbillName"
                                    required 
                                    placeholder="Mashbill Name" 
                                    id="mashbillName"
                                    class="form-control"
                                />
                            </div>
                            <div class="mb-2">
                                <label for="mashbillECode" class="form-label">External Code</label>
                                <input
                                    type="text"
                                    v-model="mashbillECode"
                                    placeholder="External Code"
                                    id="mashbillECode"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-outline-danger" 
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button 
                                type="button" 
                                @click.prevent="createMashbill" 
                                class="btn btn-outline-primary" 
                                data-bs-dismiss="modal"
                                :disabled="mashbillCode == '' && mashbillName == ''"
                            >
                                Create Mashbill
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="barrelTypeModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Barrel Type</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBarrelType"></button>
                        </div>  
                        <div class="modal-body">
                            <p class="red">{{errBarrelType}}</p>
                            <div class="mb-2">
                                <label for="bTypeCode" class="form-label">Barrel Type Code <span class="red">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Barrel Type Code"
                                    v-model.trim="bTypeCode"
                                    max="4"
                                    id="bTypeCode"
                                    class="form-control"
                                />
                                <span class="text-danger">Max Characters 4</span>
                            </div>
                            <div class="mb-2">
                                <label for="bTypeName" class="form-label">Barrel Type Name <span class="red">*</span></label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="bTypeName"
                                    v-model.trim="bTypeName"
                                    placeholder="Barrel Type Name"
                                />
                            </div>
                        </div>    
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-outline-danger" 
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-outline-primary" 
                                @click="postNewBarrelType"
                            >
                                Create Barrel Type
                            </button>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import CompaniesUsers from '../components/company/CompaniesUsers.vue';
import CompaniesBarrels from '../components/company/CompaniesBarrels.vue';
import CompaniesMashbills from '../components/company/CompaniesMashbills.vue';
import CompaniesBarrelTypes from '../components/company/CompaniesBarrelTypes.vue';
import NoItemsFound from '../components/common/NoItemsFound.vue';
import CommonActions from '@/components/actions/CommonActions.vue';
import {
    createNewMashBill,
    editCompany,
    getCompany,
    getUsers,
    getBarrels,
    getMashBills,
    createNewUser,
    getBarrelTypes,
    getCompanyActions,
    createNewBarrelType,
    createUser
} from '@/utils/auth';
import { mapState } from 'vuex';

export default {
    name: 'SingleCompany',
    data() {
        return {
            company: null,
            loading: true,
            barrels: [],
            users: [],
            barrelTypes: [],
            mashbills: [],
            barrelSelect: 25,
            allPages: 0,
            pageNo: 1,
            userActionVisible: false,
            displayUserDialog: false,
            displayBarrelDialog: false,
            recipeActionsVisible: false,
            mashbillCode: '',
            mashbillName: '',
            userCode: '',
            userName: '',
            permissions: [
               'access_inactive_objects', 'access_all_companies', 'access_all_users'
            ],
            readEndpoint: [
                "read_actions_by_barrel",
                "read_actions_by_barrel_type",
                "read_actions_by_company",
                "read_actions_by_device_measurement",
                "read_actions_by_mashbill",
                "read_actions_by_rick",
                "read_actions_by_rickhouse",
                "read_actions_by_user",
                "read_barrel",
                "read_barrel_by_code",
                "read_barrel_type",
                "read_barrel_types",
                "read_barrels",
                "read_barrels_by_barrel_type",
                "read_barrels_by_filter",
                "read_barrels_by_mashbill",
                "read_barrels_by_rick",
                "read_companies",
                "read_company",
                "read_device_measurement",
                "read_device_measurements",
                "read_mashbill",
                "read_mashbills",
                "read_rick",
                "read_rick_by_code",
                "read_rickhouse",
                "read_rickhouses",
                "read_ricks",
                "read_user",
                "read_user_token",
                "read_user_tokens",
                "read_users",
                "read_label_by_rick",
                "read_label_by_barrel"
            ],
            updateEndpoint: [
                "update_barrel",
                "update_barrel_rick",
                "update_barrel_status",
                "update_barrel_type",
                "update_company",
                "update_mashbill",
                "update_rick",
                "update_rickhouse"
            ],
            createEndpoint: [
                "create_barrel",
                "create_barrel_type",
                "create_company",
                "create_device_measurement",
                "create_mashbill",
                "create_rick",
                "create_rickhouse",
                "create_user"
            ],
            deleteEndpoint: [
                "delete_rick",
                "delete_rickhouse",
                "delete_user_token",
            ],
            endpoints: ["sign_out"],
            password: '',
            barrelCode: '',
            barrelName: '',
            bTypeCode: '',
            bTypeName: '',
            bTypeStatus: 'new',
            showCompanyPutDialog: false,
            companyActions: null,
            perPage: 25,
            currentPage: 1,
            mashbillECode: '',
            errBarrelType: '',
            counterForView: 0
        }
    },
    computed: {
        ...mapState(['accessToken'])
    },
    methods: {
        createMashbill() {
            createNewMashBill(this);
        },
        updateCompany() {
            editCompany(this);
        },
        handleSizeChange() {
            getBarrels(this, this.perPage, this.currentPage)
        },
        handleCurrentChange() {
            getBarrels(this, this.perPage, this.currentPage)
        },
        incrementPage() {
            this.pageNo++
            getBarrels(this)
        },
        decrementPage() {
            this.pageNo--
            getBarrels(this)
        },
        selectBarrelsSize() {
            getBarrels(this)
            this.pageNo = 1
        },
        postNewBarrelType() {
            if(this.bTypeCode == '' || this.bTypeCode <= 3 || this.bTypeName == '' ) this.errBarrelType = 'All fields must be entered correctly'
            else {
                createNewBarrelType(this)
                this.$el.querySelector('#closeBarrelType').click()
                this.incrementView()
            }
        },
        incrementView() {
            this.counterForView++
        },
        createUser() {
            if(this.$refs.readP.checked) {
                this.endpoints.push(...this.readEndpoint)
            }
            if(this.$refs.createP.checked) {
                this.endpoints.push(...this.createEndpoint)
            }
            if(this.$refs.updateP.checked) {
                this.endpoints.push(...this.updateEndpoint)
            }
            if(this.$refs.deleteP.checked) {
                this.endpoints.push(...this.deleteEndpoint)
            }
            createUser(this, this.permissions)
        }
    },
    components: {
        AppLoader,
        CompaniesUsers,
        CompaniesBarrels,
        CompaniesMashbills,
        CompaniesBarrelTypes,
        NoItemsFound,
        CommonActions
    },
    beforeMount() {
        getCompany(this);
        getUsers(this);
        getBarrels(this);
        getMashBills(this);
        getBarrelTypes(this);
        getCompanyActions(this);
    }
}
</script>

<style scoped>
.collapse {
    width: 100%;
    margin-top: 16px;
}

@media only screen and (max-width: 400px)  {
    .nav {
        overflow-y: scroll;
       flex-wrap: nowrap;

    }
}
</style>