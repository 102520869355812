<template>
    <div class="row" v-if="!loading && barrel !== null">
        <div class="col-md-10 offset-md-1 box mt-5">
            <h2 class="text-center text-caps">
                Barrel {{ barrel.code }}
            </h2>
            <div class="row">
                <div class="col-md-6 col-6">
                    <div class="mb-2">
                        <p>Barrel Type: </p>
                        <span class="regular-text mb-2">{{ barrel.barrelType.code }}</span>
                    </div>
                    <div class="mb-2">
                        <p>Age: </p>
                        <span class="regular-text mb-2">
                            {{ `${barrel.ageYearsAndDays[0]} years and ${barrel.ageYearsAndDays[1]} days`}}
                        </span>
                    </div>
                    <div class="mb-2">
                        <p>Gallons: </p>
                        <span class="regular-text mb-2">{{ barrel.gallons }} </span>
                    </div>
                    <div class="mb-2">
                        <p>Proof: </p>
                        <span class="regular-text mb-2">{{ barrel.proof }}</span>
                    </div>
                    <div class="mb-2">
                        <p>Mashbill: </p>
                        <span class="regular-text mb-2">{{ barrel.mashbill.code }}</span>
                    </div>
                    <div class="mb-2">
                        <p>Updated at:</p>
                        <span class="regular-text mb-2">{{ barrel.createdAt.toDateString() }} </span>
                    </div>
                </div>
                <div class="col-md-6 col-6">
                    <div class="mb-2">
                        <p>Status: </p>
                        <span class="regular-text mb-2">{{ barrel.status }}</span>
                    </div>
                    <div class="mb-2">
                        <p>Filled At: </p>
                        <span class="regular-text mb-2">{{ barrel.filledAt.toDateString() }} </span>
                    </div>
                    <div class="mb-2">
                        <p>Pounds: </p>
                        <span class="regular-text mb-2">{{ barrel.pounds }} </span>
                    </div>
                    <div class="mb-2">
                        <p>Position: </p>
                        <span class="regular-text mb-2" v-if="barrel.position !== undefined && barrel.position">{{ barrel.position }} </span>
                        <span class="regular-text mb-2" v-else>Not assigned</span>
                    </div>
                    <div class="mb-2">
                        <p>Proof Gallon: </p>
                        <span class="regular-text mb-2">{{ barrel.proofGallons }}</span>
                    </div>
                    <div class="mb-2">
                        <p>Rick: </p>
                        <span v-if="barrel.rick !== undefined" class="regular-text mb-4">{{ barrel.rick.code }}</span>
                        <span v-else class="regular-text mb-4">Not Assigned</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="mt-1">Barrel Label </h5>
                    <p>{{`${barrelLabel.substring(0, 12)}...`}}</p>
                </div>
                <div class="col-md-3 align-items-center d-flex">
                    <button 
                        class="btn btn-outline-primary"
                        @click="printLabel(barrelLabel)"
                    >
                        Print Label
                    </button>
                </div>
            </div>
            <div>
                <div class="text-center">
                    <button 
                        class="edit-button btn btn-outline-primary" 
                        data-bs-toggle="modal" 
                        data-bs-target="#updateBarrel" 
                        v-if="!barrel.rick" 
                        aria-label="update barrel"
                        v-tippy="{ content: 'Update Barrel' }"
                    >
                        <i class="fa-solid fa-pen-nib"></i>
                    </button>
                    &nbsp;
                    <button 
                        class="btn edit-button btn-outline-success" 
                        data-bs-toggle="modal" 
                        data-bs-target="#unloadBarrel" 
                        v-if="barrel.rick && barrel.rick.barrelCount == barrel.position" 
                        aria-label="unload barrel"
                        v-tippy="{ content: 'Unload Barrel'}"
                    >
                        <i class="fa-solid fa-upload"></i>
                    </button>
                    &nbsp;
                    <button 
                        class="btn btn-outline-info edit-button" 
                        data-bs-toggle="modal" 
                        data-bs-target="#loadBarrel" 
                        v-if="!barrel.rick" 
                        aria-label="load barrel"
                        v-tippy="{ content: 'Load Barrel', arrow: true }"
                    >
                        <i class="fa-solid fa-truck-ramp-box"></i>
                    </button>
                    &nbsp;
                    <button 
                        class="btn btn-outline-success edit-button"
                        data-bs-toggle="modal"
                        data-bs-target="#statusModal"
                        v-if="!barrel.rick"
                        v-tippy="{content: 'update status'}"
                    >
                        <i class="fa-solid fa-ellipsis"></i>
                    </button>
                </div>
            </div>
            <div class="accordian mb-2" id="accordianActions">
                <h1 class="accordian-title">
                    <button class="accordion-button mt-2 mb-2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <strong> Actions </strong>
                    </button>
                </h1>
                <div 
                    id="collapseOne" 
                    class="accordion-collapse collapse" 
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                >
                    <div class="accordion-body">
                        <barrel-actions v-for="action in actions" :key="action.id" :action="action" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="updateBarrel" ref="updateModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="updateBarrelHeader">Update Barrel</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="updateModalClose"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mt-2">
                            <p class="red-text">{{gallonsError}}</p>
                            <label for="gallons" class="form-label">Gallons:</label>
                            <input type="text" class="form-control" id="gallons" v-model="gallons" :placeholder="barrel.gallons">
                        </div>
                        <div class="form-group mt-2">
                            <p class="red-text">{{proofError}}</p>
                            <label for="proof" class="form-label">Proof: </label>
                            <input type="number" step="0.01" class="form-control" id="proof" v-model="proof" :placeholder="barrel.proof">
                        </div>
                        <div class="form-group mt-2">
                            <p class="red-text">{{poundError}}</p>
                            <label for="pounds" class="form-label">Pounds: </label>
                            <input type="number" step="0.01" class="form-control" id="pounds" v-model="pounds" :placeholder="barrel.pounds">
                        </div>
                        <div class="form-group mt-2">
                            <label for="notes" class="form-label">Notes: </label>
                            <textarea type="text" class="form-control" id="notes" v-model="notes" placeholder="notes"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" @click="updateBarrel" >Update Barrel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="unloadBarrel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="unloadBarrelHeader">Unload Barrel</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="unloadBarrelModal"></button>
                    </div>
                    <div class="modal-body">
                        <p class="text-center">Are you sure you would like to unload this barrel?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" @click="unloadBarrel">Unload Barrel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- load barrel modal -->
        <div class="modal fade" id="loadBarrel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="unloadBarrelHeader">Load Barrel</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="rickCode" class="form-label">Rick: </label>
                            <input type="text" v-model="rickCode" class="form-control" id="rickCode">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary"  @click="loadBarrel"  data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#confirmLoadBarrel">Load Barrel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="confirmLoadBarrel">
            <div class="modal-dialog ">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalToggleLabel2">Confirm Rick</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="confirmRickModal"></button>
                    </div>
                    <div class="modal-content">
                        <p class="text-center">Are you sure you want to load barrel {{barrel.code}} to rick {{rickCode}}</p>
                    </div>
                    <div class="modal-footer text-center">
                        <button class="btn btn-outline-primary" @click="confirmLoadBarrel">
                            Confirm Rick
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="modal fade" id="statusModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Status</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeStatus"></button>        
                    </div>
                    <div class="modal-body">
                        <p>{{statusErr}}</p>
                        <div class="form-group">
                            <label for="status" class="form-label">Barrel Status</label>
                            <select v-model="status" class="form-select">
                                <option 
                                    v-for="status in barrelStatuses " 
                                    :key="status"
                                    :value="status" 
                                    :label="status" 
                                />
                            </select> 
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-outline-primary"  @click="updateStatus">Update Barrel Status</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue'
const RickhouseApi = require('rickhouse_api')
import BarrelActions from '@/components/actions/BarrelActions.vue'
import { mapState } from 'vuex';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'
import { 
    getActionsFromBarrel, 
    readBarrel, 
    updateBarrelStatus, 
    getBarrelLabel, 
    unloadBarrelByid, 
    updateBarrel, 
    getRickFromCode, 
    updateBarrelsRick
} from '../utils/auth'
import { useToast } from "vue-toastification";

export default {
    name: 'SingleBarrel',
    data() {
        return {
            loading: true,
            barrel: null,
            barrelLabel: '',
            barrelStatusValue: '',
            value: '',
            actions: [],
            status: '',
            printStatus: false,
            gallons: '',
            proof: '',
            pounds: '',
            notes: '',
            gallonsError: '',
            proofError: '',
            poundError: '',
            barrelCodeError: '',
            barrelCount: 0,
            rickCode: '',
            rickId: '',
            statusErr: '',
            barrelStatuses: ['exists','deleted', 'tendered', 'bottled', 'sold', 'damaged']
        }
    },
    components: {
        AppLoader,
        BarrelActions,
    },
    computed: {
        ...mapState(['accessToken'])
    },
    methods: {
        updateStatus() {
            if(this.barrel.status == this.status) statusErr = "Barrel can't have same status"
            updateBarrelStatus(this)
        },
        async printLabel(serial) {
            const toast = useToast()
            try {
                const browserPrint =  new ZebraBrowserPrintWrapper()
                const defaultPrinter =  await browserPrint.getDefaultPrinter()
                browserPrint.setPrinter(defaultPrinter)
                const printerStatus = await browserPrint.checkPrinterStatus()

                if(printerStatus.isReadyToPrint) {
                    const zpl = `^XA
                        ^BY2,2,100
                        ^FO20,20^BC^FD${serial}^FS
                        ^XZ`
                    browserPrint.print(zpl)
                    toast.success('Printer has begun printing')
                }
                else {
                    console.warn("Error/s: ", printerStatus.errors)
                    toast.warning(`The printer had shows error(s) of ${printerStatus.errors}`)
                }
            }
            catch(error) {
                console.warn('error in catch block', error)
            }
        },
        unloadBarrel() {
            unloadBarrelByid(this)
        },
        updateBarrel() {
            if(this.barrel.gallons < this.gallons) this.gallonsError = "Gallons must be less than original"
            else if(this.barrel.proof > this.proof) this.proofError = "Proof must be higher"
            else if(this.proof % 1 == 0) this.proofError = 'Proof must contain decimal'
            else if(this.barrel.pounds < this.pounds) this.poundError = 'Pounds must be lower'
            else {
                updateBarrel(this)
                this.$el.querySelector('#updateModalClose').click()
            }
        },
        updateFeatureCount() {
            this.barrelCount++
        },
        loadBarrel() {
            getRickFromCode(this)
        },
        confirmLoadBarrel() {
            updateBarrelsRick(this)      
        }
    },
    beforeMount() {
        readBarrel(this);
        getActionsFromBarrel(this);
        getBarrelLabel(this);
    },
}
</script>

<style scoped>
p {
    margin-bottom: 0.25rem;
}

.inline-block {
    display: inline-block;
}

.edit-button {
    border-radius: 50%;
}

.red-text {
    color: #B83A4B;
}
</style>
