<template>
    <router-link class="primary-text" :to="{name: 'SingleMashbill', params: {id: mashbill.id}}">
        <div class="mb-4">
                <p>
                    <i class="fa-solid fa-flask mash-list"></i>
                    <span class="ms-3">
                        {{mashbill.code}}
                    </span>
                </p>
        </div>
    </router-link>
</template>

<script>

export default {
    name: 'CompaniesMashbills',
    props: ['mashbill']
}
</script>

<style scoped>
.mash-list {
    color: #67C23A;
    height: 20px;
}
</style>