<template>
    <div class="row" v-if="!loading">   
        <div class="col-md-3 filter-barrel d-md-block d-none">
            <div class="block text-center">
                <span class="span-block">Mashbill</span>
                <div class="col-md-10 offset-md-1">
                    <select class="form-select filter-select" v-model="mashbillSelected">
                        <option v-for="mash in mashIds" :key="mash.id" :value="mash.mashbill.id"
                            :label="mash.mashbill.code" />
                    </select>
                </div>
            </div>
            <div class="block text-center">
                <span class="span-block">Barrel Type</span>
                <div class="col-md-10 offset-md-1">
                    <select class="form-select filter-select" v-model="barrelTypeSelected">
                        <option v-for="barrelType in barrelTypeIds" :key="barrelType.id"
                            :value="barrelType.barrelType.id" :label="barrelType.barrelType.code" />
                    </select>
                </div>
            </div>
            <div class="block text-center">
                <span class="span-block">Companies</span>
                <div class="col-md-10 offset-md-1">
                    <select class="form-select filter-select" v-model="companySelected">
                        <option v-for="company in companyIds" :key="company.id" :value="company.id"
                            :label="company.code" />
                    </select>
                </div>
            </div>
            <div class="block text-center">
                <span class="span-block">Start Date </span>
                <div class="col-md-10 offset-md-1">
                    <flat-pickr
                        @keydown="preventDef"
                        id="startDate"
                        v-model="fromDate"
                        class="flat-picker form-control filter-select"
                        placeholder="YYYY-MM-DD"
                    />
                </div>
            </div>
            <div class="block text-center">
                <span class="span-block">End  Date</span>
                <div class="col-md-10 offset-md-1">
                    <flat-pickr 
                        @keydown="preventDef"
                        id="endDate"
                        v-model="toDate"
                        class="flat-picker form-control filter-select"
                        placeholder="YYYY-MM-DD"
                        :disabled="fromDate ==  ''"
                    />
                </div>
            </div>
            <div class="button-row text-center mb-2">
                <button 
                    type="button" 
                    class="btn btn-outline-primary"
                     @click.prevent="filterBarrelsByDate">
                    Filter
                </button>
                <button 
                    type="button" 
                    class="btn btn-outline-secondary ms-2 right-button"
                    @click="clearSearch"
                >
                Clear
                </button>
            </div>
            <div class="text-center">
                <p>Barrel Row Select</p>
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="fieldCheck" 
                    v-model="selectAll"
                    @change="checkStatus" 
                    ref="selectAllCheckbox"
                />
                <label for="fieldCheck" class="ms-2">{{selectAll ? 'Deselect All' : 'Select All'}}</label>
            </div>
            <div class="button-block text-center">
                <router-link :to="{ name: 'BarrelListSelected' }">
                    <button type="button" class="btn btn-outline-secondary">Barrel Select</button>
                </router-link>
            </div>
        </div> 
        <div class="col-md-9 col-11">
            <h2 class="text-center heading mt-4">Barrel List</h2>
            <p class="text-center" v-if="barrelList.length == 0 || barrelList == null">There are no barrels that match</p>
            <table class="table table-responsive" v-else>
                <thead>
                    <tr class="text-caps">
                        <th class="text-center">Select</th>
                        <th>Name</th>
                        <th class="d-sm-none d-md-table-cell d-none d-sm-table-cell">Mashbill</th>
                        <th class="d-sm-none d-md-table-cell d-none d-sm-table-cell">Type</th>
                        <th class="d-sm-none d-md-table-cell d-none d-sm-table-cell">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <barrel-filter-row 
                        v-for="barrel in barrelList" 
                        :key="barrel.id" 
                        :barrel="barrel" 
                    />
                </tbody>
            </table>
            <div class="row mb-5">
                <div class="col-2">
                    <select name="" id="" class="form-select" v-model="perPage" @change="handleSizeChange">
                        <option disabled>--select page size--</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="col-8">
                    <button 
                        class="btn btn-outline-secondary small-btn ms-2 ms-md-0" 
                        :disabled="pageNo == 1"
                        @click="decrementPageNo"
                    >
                        <i class="fa-solid fa-chevron-left"></i>
                    </button>
                    <span class="ms-3 me-3">{{pageNo}} / {{allPages}}</span>
                    <button 
                        class="btn btn-outline-secondary small-btn"  
                        @click="incrementPageNo"
                    >
                        <i class="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-block d-md-none mt-2 col-1">
            <div class="">
            <a 
                class="btn" 
                data-bs-toggle="offcanvas" 
                href="#barrelFilterCanvas" 
                role="button"
                v-tippy="{content: 'Filter Barrels', placement: 'right'}"
            >
            <i class="fa-sharp fa-light fa-filter"></i>
            </a>
            </div>
            <div class="offcanvas offcanvas-end" id="barrelFilterCanvas" tabindex="-1" >
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title small-caps">Filter Barrels</h5>
                    <button type="button" class="btn-close btn-close white btn-close-white " data-bs-dismiss="offcanvas" aria-label="Close" style="color: white !important;">
                    </button>
                </div>
                <div class="offcanvas-body">
                    <div class="block text-center">
                        <span class="span-block">Mashbill</span>
                        <div class="col-md-10 offset-md-1 col-sm-8 offset-sm-2">
                            <select class="form-select filter-select" v-model="mashbillSelected">
                                <option v-for="mash in mashIds" :key="mash.id" :value="mash.mashbill.id"
                                    :label="mash.mashbill.code" />
                            </select>
                        </div>
                    </div>
                    <div class="block text-center">
                        <span class="span-block">Barrel Type</span>
                        <div class="col-md-10 offset-md-1">
                            <select class="form-select filter-select" v-model="barrelTypeSelected">
                                <option v-for="barrelType in barrelTypeIds" :key="barrelType.id"
                                    :value="barrelType.barrelType.id" :label="barrelType.barrelType.code" />
                            </select>
                        </div>
                    </div>
                    <div class="block text-center">
                        <span class="span-block">Companies</span>
                        <div class="col-md-10 offset-md-1">
                            <select class="form-select filter-select" v-model="companySelected">
                                <option v-for="company in companyIds" :key="company.id" :value="company.id"
                                    :label="company.code" />
                            </select>
                        </div>
                    </div>
                    <div class="block text-center">
                        <p>{{fromDateError}}</p>
                        <span class="span-block">Start Date </span>
                        <div class="col-md-8 offset-md-2">
                            <flat-pickr 
                                @keydown="preventDef"
                                v-model="fromDate"
                                class=" form-control filter-select"
                                placeholder="YYYY-MM-DD"
                            />
                        </div>
                    </div>
                    <div class="block text-center">
                        <span class="span-block">End Date </span>
                        <div class="col-md-8 offset-md-2 mb-4">
                            <flat-pickr 
                                @keydown="preventDef"
                                v-model="toDate"
                                class="form-control filter-select"
                                placeholder="YYYY-MM-DD"
                            />
                        </div>
                    </div>
                    <div class="button-row text-center mb-2">
                        <button type="button" class="btn btn-primary" @click.prevent="filterBarrelsByDate">
                            Filter
                        </button>
                        <button 
                            type="button" 
                            class="btn btn-danger ms-2 right-button"
                            @click="clearSearch"
                        >
                        Clear
                        </button>
                    </div>
                    <div class="text-center">
                        <p>Barrel Row Select</p>
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            id="fieldCheck" 
                            v-model="selectAll"
                            @change="checkStatus" 
                            ref="selectAllCheckbox"
                        />
                        <label for="fieldCheck" class="ms-2">{{selectAll ? 'Deselect All' : 'Select All'}}</label>
                    </div>
                    <div class="button-block text-center">
                        <router-link :to="{ name: 'BarrelListSelected' }">
                            <button type="button" class="btn btn-success">Barrel Select</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <app-loader />
    </div>
</template>

<script>
import { readBarrelFilter, readBarrelFilterBegin, getCompanyForFilter, getInitialBarrelFilter } from '../utils/auth'
import AppLoader from '../components/AppLoader.vue'
import BarrelFilterRow from '@/components/barrels/BarrelFilterRow.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
    name: 'BarrelList',
    data() {
        return {
            loading: true,
            fromDate: "",
            toDate: "",
            perPage: 25,
            pageNo: 1,
            allPages: 1,
            disabledButton: false,
            barrelList: null,
            companyIds: [],
            companySelected: "",
            mashIds: [],
            mashbillSelected: "",
            barrelTypeIds: [],
            barrelTypeSelected: "",
            selectAll: false,
            fromDateError: ''
        }
    },
    components: {
        AppLoader,
        BarrelFilterRow,
        flatPickr
    },
    methods: {
        async filterBarrelsByDate() {
            await readBarrelFilterBegin(this)
            this.perPage = 25
        },
        filterBarrelsMobile() {
            this.readBarrelFilterBegin()
            this.$el.querySelector('.btn-close-white').click()
        },
        handleSizeChange() {
            this.pageNo = 1
            readBarrelFilter(this);
        },
        getCompaniesForFilter() {
            getCompanyForFilter(this)
        },
        clearSearch() {
            this.fromDate = ''
            this.toDate = ''
            this.barrelTypeSelected = ''
            this.mashbillSelected = ''
            this.companySelected = ''
            getInitialBarrelFilter(this)
        },
        checkStatus() {
            if (this.$refs.selectAllCheckbox.label == 'Select All' && this.$refs.selectAllCheckbox.checked == false) {
                this.$store.state.barrelListSelection = []
            }

            this.$store.commit('setSelectCheck', this.selectAll)
        },
        getPagValue(btnValue) {
            this.pageNo = btnValue
            readBarrelFilter(this)
        },
        incrementPageNo() {
            this.pageNo++
            readBarrelFilter(this)
        },
        decrementPageNo() {
            this.pageNo--
            readBarrelFilter(this)
        },
        preventDef(event) {
            if(event) {
                event.preventDefault()
            }
        }
    },
    beforeMount() {
        getInitialBarrelFilter(this)
        this.getCompaniesForFilter()
    },
}
</script>

<style scoped>
.filter-barrel {
    margin-top: 72px;
}

.span-block {
    display: block;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
}

.block {
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}


.button-row {
    margin-top: 16px;
}


.filter-select {
    width: 100% !important;
}

.button-block {
    margin-top: 12px;
}

.heading {
    font-variant: small-caps;
}

.flat-picker {
    width: 66.66666667%;
    padding: 5px 5px;
}

@media only screen and (max-width: 600px) {
    .right-button {
        margin-left: 0 !important;
    }
    .small-btn {
        width: 32px;
    }
}

.offcanvas {
    background-color: #545c64;
    color: white !important;
}

.offcanvas-body {
    overflow: scroll;
}
.white {
    color: white !important;
}
</style>>
