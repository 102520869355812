<template>
    <router-link :to="{name: 'SingleBarrel', params: {id: barrel.id}}">
        <div class="row single-list primary-text">
            <div class="col-md-5 offset-md-1">
                <p>{{barrel.code}}</p>
            </div>
            <div class="col-md-3">
                <p>
                    {{barrel.mashbill.code}}
                </p>
            </div>
            <div class="col-md-3 badge-section">
                <span 
                    class="badge"
                    :class="barrel.isActive ? 'text-bg-success' : 'text-bg-danger'"
                >
                    {{ barrel.isActive ? 'active' : 'inactive'}}
                </span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'SingleBarrelListItem',
    data() {
        return {

        }
    },
    props: ['barrel']
}
</script>

<style scoped>
.badge-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>