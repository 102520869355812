<template>
    <div>
        <div class="container-fluid mt-2">
            <a 
                class="btn sidebar-icon" 
                data-bs-toggle="offcanvas" 
                href="#sidebarNavbar" 
                role="button"
            >
                <i class="fa-solid fa-bars"></i>
            </a>
            <div class="offcanvas offcanvas-start" id="sidebarNavbar" tabindex="-1" >
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title small-caps">Luca Mariano Distillery</h5>
                    <button type="button" class="btn-close btn-close-white white" style="color: white !important;" data-bs-dismiss="offcanvas" aria-label="Close">
                    </button>
                </div>
                <div class="offcanvas-body">
                    <div v-if="accessToken == null" class="menu-item text-center">
                        <router-link class="white-text" :to="{ name: 'Home' }">
                            <i class="fa-solid fa-house"></i>
                            <span class="ms-2">Viola Farms</span>
                        </router-link>
                    </div>
                    <div v-else class="nav-container">
                        <div class="menu-item w-50 mx-auto">
                            <router-link class="white-text" :to="{ name: 'Rickhouses' }" @click="navLinkClick">
                                <i class="fa-solid fa-warehouse"></i>
                                <span class="ms-2">Rickhouses</span>
                            </router-link>
                        </div>
                        <div class="menu-item w-50 mx-auto mt-2">
                            <router-link :to="{ name: 'Companies' }" class="white-text" @click="navLinkClick">
                                <i class="fa-solid fa-people-group"></i>
                                <span class="ms-2">Companies</span>
                            </router-link>
                        </div>
                        <div class="menu-item w-50 mx-auto mt-2">
                            <router-link :to="{ name: 'BarrelList' }" class="white-text" @click="navLinkClick">
                                <i class="fa-solid fa-list"></i>
                                <span class="ms-2">Barrel List</span>
                            </router-link>
                        </div>
                        <div class="menu-item w-50 mx-auto mt-2">
                            <router-link :to="{ name: 'Select'}" class="white-text" @click="navLinkClick">
                                <i class="fa-solid fa-qrcode"></i>
                                <span class="ms-2">QR Reader</span>
                            </router-link>
                        </div>
                        <div class="menu-item w-50 mx-auto white-text mt-2">
                            <a class="" href="#" @click.prevent="signOut">
                                <i class="fa-solid fa-user ps-0"></i>
                                <span class="ms-2">Sign Out </span>
                            </a>
                        </div>
                        <div class="version text-center">
                            <h4>Version: {{ version }} </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { version } from '../../../package.json'
export default {
    name: 'NewSidebar',
    data() {
        return {
            version
        }
    },
    computed: {
        ...mapState(['userLoggedIn','accessToken'])
    },
    methods: {
        signOut() {
            this.$store.dispatch('signOut');
            this.$el.querySelector('.btn-close-white').click()
            if (this.$route.meta.requiresAuth) this.$router.push({ name: 'Home' });
        },
        navLinkClick(e) {
            this.$el.querySelector('.btn-close-white').click()
        }
    },
}
</script>

<style scoped>
.offcanvas {
    background-color: #545c64;
    color: white !important;
}

.white {
    color: white !important;
}

.small-caps {
    font-variant: small-caps;
}

.btn-close {
    color: white !important;
}

.sidebar-icon {
    width: 40px;
}

.version {
    position: absolute;
    bottom: 26px;
    left: 25%;
}

@media only screen and (max-width: 600px) {
    .sidebar-icon {
        width: 100% !important;
        height: 100%;
    }
}
</style>