<template>
    <div class="wrapper">
        <div>
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3">
                    <div class="video-container">
                        <div id="qr-code-region" style="height: 250px !important"></div>
                    </div>
                </div>
            </div>
            <div class="row input-row">
                <div class="col-12 col-md-6 offset-md-3">
                    <input 
                        type="text" 
                        class="form-control" 
                        id="qr-scan-result" 
                        v-model="text"
                        placeholder="Barrel ID or Rick ID" />
                </div>
                <div class="button-container col-12 col-md-6 offset-md-3">
                    <Transition name="fade">
                        <button 
                            class="btn btn-primary action-button mb-2 w-100" 
                            :disabled="disabledStatus"
                            @click="enterBarrelCode"
                            v-if="text.includes('-') && !text == ''"
                        >
                            Read Barrel
                        </button>
                    </Transition>
                    <button 
                        class="btn btn-info mb-2 w-100" 
                        :disabled="disabledStatus" 
                        @click="enterRickCode"
                        v-if="!text.includes('-') && !text == ''"
                    >
                        Read Rick
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode"
import { getBarrelFromSelect, getRickFromSelect } from "@/utils/auth"
import { useToast } from "vue-toastification"

export default {
    name: "Select",
    data() {
        return {
            text: "",
            config: {
                fps: 2,
                qrbox: {
                    width: 400,
                    height: 200,
                },
                aspectRatio: 1.0,
            },
            disabledStatus: false,
            barrelId: null,
            rickId: null,
            rick: null,
            cameraId: "",
            html5QrCode: null
        }
    },
    methods: {
        onScanSuccess(decodedText, decodedResult) {
            const toast = useToast()
            this.text = decodedText
            this.html5QrCode.stop()

        },
        onScanFailure() {
            console.log("QR scanner failed")
            const toast = useToast()
            toast.error('Something happened')
        },
        enterBarrelCode() {
            getBarrelFromSelect(this)
        },
        enterRickCode() {
            getRickFromSelect(this)
        },
    },
    mounted() {
        const toast = useToast()

        this.html5QrCode = new Html5Qrcode("qr-code-region")

        this.html5QrCode
        .start(
            { facingMode: "environment" },
            this.config,
            this.onScanSuccess
        ).catch((err) => {
            console.log("in the catch block")
            toast.error(`Error: ${err}`) 
        })

    }
}
</script>

<style scoped>
.video-container {
    height: 250px;
    width: 100%;
    background-color: white;
    margin-top: 36px;
    margin-bottom: 64px;
}

.input-row {
    margin-top: 24px;
}

#qr-scan-result {
    text-transform: uppercase;
    display: block;
    margin-top: 24px;
}

.button-container {
    margin-top: 20px;
}

.button-container>button {
    margin-left: 0;
    margin-bottom: 16px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
