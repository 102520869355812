<template>
    <div class="row justify-content-center congrats-block">
        <i class="fa-solid fa-circle-check display-icon"></i>
        <h2 class="text-center">Congrats</h2>
        <p class="text-center">You successfully completed your task</p>
        <div class="col-12 justify-content-center text-center">
            <router-link :to="{name: 'Select'}">
                <button class="btn btn-outline-secondary">Back to Scan</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Confirmation'
}
</script>

<style scoped>
.display-icon {
    display: block;
    margin: 40px auto 24px auto;
}

.congrats-block {
    flex-direction: column;
}

.display-icon {
    height: 60px;
    color: green;
}
</style>