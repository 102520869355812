<template>
    <div class="row">
        <div class="container-fluid">
            <div class="col-md-10 offset-md-1 mt-5 box text-center">
                <h2 class="text-center">Oops, Something Happened</h2>
                <p class="text-center">There was an issue scanning the QR Code</p>
                <router-link :to="{name: 'Select'}">
                    <button class="btn btn-primary">
                        Go back to scan
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Problems'
}
</script>