<template>
<div>
    <div class="col-12">
        <p class="text-center">Scan rick code to confirm target rick</p>
        <div class="video-container">
            <div id="qr-code-region" style="height: 200px;"></div>
          </div>
          <input
            type="text"
              
          />   
    </div>
</div>
</template>

<script>
export default {
    name: 'MoveBarrel'
}
</script>

<style scoped>
.video-container {
  border: 1px solid black;
  border-radius: 2px;
  height: 200px;
  width: 100%;
  background-color: white;
  margin-top: 24px;
}

#qr-scan-result {
  margin: 24px;
}

.input-row {
  margin-top: 16px;
}

.btn-container {
  margin-top: 20px;
}

.btn-container > button {
  margin-left: 0;
  margin-bottom: 16px;
}

.hidden {
  display: none;
}
</style>
