<template>
    <div>
        <div class="row mt-5 mt-md-2"  v-if="!loading">
            <div class="col-12 col-md-10 offset-md-1 box mt-4">
                <h2>{{ barrel.code }} Info</h2>
                <p><strong>Proof: </strong>{{ barrel.proof }}</p>
                <p><strong>Gallons: </strong>{{ barrel.gallons }}</p>
                <p><strong>Pounds: </strong>{{ barrel.pounds }} </p>
                <p><strong>Mashbill: </strong>{{ barrel.mashbill.code }}</p>
                <p><strong>Barrel Type: </strong>{{ barrel.barrelType.code }}</p>
                <p><strong>Status: </strong>{{ barrel.status }}</p>
                <div v-if="typeof barrel.rick == 'object'">
                    <p><strong>Position: </strong>{{barrel.position}}</p>
                    <p><strong>Rick: </strong>{{ barrel.rick.code }}</p>
                    <p><strong>Rickhouse:: </strong>{{ barrel.rick.rickhouse.code }}</p>
                    <p><strong>Barrel Count: </strong>{{ barrel.rick.barrelCount }} /
                        {{ barrel.rick.barrelCapacity }}</p>
                    <p><strong>Floor: </strong>{{ barrel.rick.properties.floor }}</p>
                    <p><strong>Row: </strong>{{ barrel.rick.properties.row }}</p>
                    <p><strong>Level: </strong>{{ barrel.rick.properties.level }}</p>
                </div>
                <div v-else>
                    <p><strong>Rick: </strong>None</p>
                    <p><strong>Rickhouse: </strong>None</p>
                    <p><strong>Barrel Count:: </strong>None</p>
                    <p><strong>Floor: </strong>None</p>
                    <p><strong>Row: </strong>None</p>
                    <p><strong>Level: </strong>None</p>
                </div>
            </div>
           
            <div class="button-container d-flex justify-content-center">
                <!-- <router-link :to="{ name: 'LoadBarrels', params: { id: this.$route.params.id } }"> -->
                    <button 
                        type="primary" 
                        class="action-button mb-4 btn btn-outline-primary mr-2"
                        :disabled="typeof barrel.rick == 'object'"
                        @click="loadBarrel"
                    >
                        Load Barrel
                    </button>
                <!-- </router-link> -->
                <button 
                    v-if="barrel.position !== null || barrel.rick !== undefined && barrel.rick.barrelCount == barrel.position" 
                    class="mb-4 btn btn-outline-success" 
                    :disabled="typeof barrel.rick !== 'object'" 
                    @click="barrelUnload"
                >
                    Unload
                </button>
                <button 
                    v-else 
                    class="btn btn-outline-success ml-2"
                     >
                    Unload
                </button>
            </div>

        </div>
        <div class="row" v-else>
            <app-loader />>
        </div>
    </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import { getBarrelById, getActionsBarrels, unloadBarrelByid } from '@/utils/auth'


export default {
    name: 'ReadBarrel',
    components: {
        AppLoader
    },
    data () {
        return {
        barrel: null,
        loading: true,
        actions: [],
        errMsg: ''
        }
    },
    methods: {
        toggleSlider () {
            const slider = document.getElementById('slider-toggle')
            slider.classList.toggle('close')
        },
        barrelUnload () {
            unloadBarrelByid(this)
        },
        loadBarrel () {
            this.$router.push({name: 'LoadBarrel', params: {id: this.$route.params.id}})
        }
    },
    async beforeMount () {
        await getBarrelById(this)
        getActionsBarrels(this)
    }

}
</script>

<style scoped>
p {
    margin-top: 8px;
    margin-bottom: 8px;
}

.button-container {
    margin-top: 20px;
}

.action-button {
    margin-right: 8px;
}

.slider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    background-color: #fff;
    color: #000;
    transition: all 0.42s ease-in-out;
}

.right-side {
    margin-left: auto;
    margin-top: 16px;
    margin-right: 16px;
}

.close {
    top: 100%;
    height: 0;
}

.margin-bot {
    margin-bottom: 16px;
}

.divider {
    background-color: #545c64;
    height: 2px;
    width: 100%;
}
</style>