import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import BarrelList from '@/views/BarrelList.vue'
import BarrelListSelected from '@/views/BarrelListSelected.vue'
import Rickhouses from '@/views/Rickhouses.vue'
import Companies from '../views/Companies.vue'
import RickhouseOverview from '@/views/RickhouseOverview.vue'
import SingleCompany from '@/views/SingleCompany.vue'
import SingleRick from '@/views/SingleRick.vue'
import SingleBarrel from '@/views/SingleBarrel.vue'
import SingleMashbill from '@/views/SingleMashbill.vue'
import SingleBarrelType from '@/views/SingleBarrelType.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import SingleUser from '@/views/SingleUser.vue'
import CreateBarrel from '@/views/CreateBarrel.vue'
import ReadBarrel from '@/views/QRViews/ReadBarrel.vue'
import LoadBarrels from '@/views/QRViews/LoadBarrels.vue'
import MoveBarrel from '@/views/QRViews/MoveBarrel.vue'
import Select from '@/views/QRViews/Select.vue'
import UnloadBarrel from '@/views/QRViews/UnloadBarrel.vue'
import ReadRick from '@/views/QRViews/ReadRick.vue'
import Reconciliations from '@/views/Reconciliations.vue'
import Problems from '@/views/QRViews/Problems'
import RecSelect from '@/views/QRViews/RecSelect'
import Confirmation from '@/views/QRViews/Confirmation.vue'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/houses',
    name: 'Rickhouses',
    component: Rickhouses,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rickhouse/:id',
    name: 'RickhouseOverview',
    component: RickhouseOverview ,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rick/:id',
    name: 'SingleRick',
    component: SingleRick,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/barrel/:id',
    name: 'SingleBarrel',
    component: SingleBarrel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/company/:id',
    name: 'SingleCompany',
    component: SingleCompany,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id',
    name: 'SingleUser',
    component: SingleUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mashbill/:id',
    name: 'SingleMashbill',
    component: SingleMashbill,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/barrel-types/:id',
    name: 'SingleBarrelType',
    component: SingleBarrelType,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create-barrel/:id',
    name: 'CreateBarrel',
    component: CreateBarrel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/barrel-list',
    name: 'BarrelList',
    component: BarrelList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/barrel-list-selected',
    name: 'BarrelListSelected',
    component: BarrelListSelected,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:'/read-barrel/:id',
    name: 'ReadBarrel',
    component: ReadBarrel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/read-rick/:id',
    name: 'ReadRick',
    component: ReadRick,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/load-barrel/:id',
    name: 'LoadBarrel',
    component: LoadBarrels,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unload-barrel',
    name: 'UnloadBarrel',
    component: UnloadBarrel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/move-barrel',
    name: 'MoveBarrel',
    component: MoveBarrel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/select',
    name: 'Select',
    component: Select,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/problems',
    name: 'Problems',
    component: Problems,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reconciliation/select/:id',
    name: 'RecSelect',
    component: RecSelect,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reconciliations/:id',
    name: 'Reconciliations',
    component: Reconciliations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(localStorage.getItem('accessKey') == undefined) {
      next({
        name: 'Home'
      })
    }
    else{
      next()
    }
  }
  else {
    next()
  }
})
export default router
