<template>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center mt-4">Load Barrel</h2>
        <div class="video-container">
          <div id="qr-code-region" style="height: 250px !important;"></div>
        </div>
        <!-- <div class="col-12 col-md-6 offset-md-3">
          <input 
            type="text"
            v-model="text"
            class="input-row form-control"
            placeholder="Rick ID"
            @keyup.enter="enterRickCode"
          />
        </div> -->
        <div class="btn-container text-center">
          <button
            class="margin-bot action-button btn btn-primary"
            :disabled="rickId == ''"
            @click="barrelRickUpdate"
          >
            Confirm Rick
          </button>
        </div>
      </div>      
    </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode'
import { getBarrelById, updateBarrelsRick, getRickByCode, getActionsFromRicks } from '@/utils/auth'

export default {
    name: 'LoadBarrels',
    data () {
    return {
      loading: true,
      barrel: null,
      rickId: '',
      disabledStatus: true,
      qrbox: {
        width: 400,
        height: 192
      },
      fps: 10,
      text: '',
      rickActions: null,
      disableButtonStatus: true,
      html5QrCode: null
    }
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.text = decodedText
      this.enterRickCode()
      this.html5QrCode.stop()
    },
    barrelRickUpdate () {
      updateBarrelsRick(this)
    },
    enterRickCode () {
      getRickByCode(this)
      // getActionsFromRicks(this)
    }
  },
  beforeMount () {
    getBarrelById(this)
  },
  mounted () {
    const config = {
      fps: this.fps,
      aspectRatio: 1,
      qrbox: this.qrbox
    }
    this.html5Qrcode = new Html5Qrcode('qr-code-region')
    this.html5Qrcode
      .start({ facingMode: 'environment' }, config, this.onScanSuccess)
      .catch((err) => {
        console.error(err)
      })
  }
}
</script>

<style scoped>
.video-container {
  height: 250px;
  width: 100%;
  background-color: white;
  margin-top: 24px;
  margin-bottom: 64px;
}

#qr-scan-result {
  margin: 24px;
}

.input-row {
  margin-top: 16px;
}

.btn-container {
  margin-top: 20px;
}

.btn-container > button {
  margin-left: 0;
  margin-bottom: 16px;
}

.hidden {
  display: none;
}
</style>
