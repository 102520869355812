<template>
    <tr>
        <td>{{barrel.code}}</td>
        <td>{{barrel.position}}</td>
    </tr>
</template>

<script>
    export default {
        name: 'BarrelListStatus',
        props: ['barrel']
    }
</script>