<template>
        <tr>
            <td class="text-capitalize">
                <router-link class="primary-text" :to="{name: 'SingleRick', params: {id: rick.id}}">
                {{rick.name}}
                </router-link>
            </td>
            <td>
                {{rick.updatedAt.toDateString()}}
            </td>
            <td v-if="rick.reconciliationStatus">
                <span class="badge text-capitalize" :class="rick.reconciliationStatus == 'created' ? 'bg-aqua' : 'bg-heather'">
                    {{ rick.reconciliationStatus}}
                </span>
            </td>
            <td v-else>
            </td>
        </tr>
</template>

<script>
export default {
    name: 'SingleRickListItem',
    props: ['rick']
}
</script>

<style scoped>

@media only screen and (max-width: 400px)  {
    .turncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}
</style>