<template>
  <div class="row">
    <div class="col-md-12">
      <h3>{{action.payload.updatedAt.toDateString() }}</h3>
      <p>Code: {{action.payload.code}}</p>
      <p>Name: {{action.payload.name}}</p>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonActions',
  props: ['action']  
}
</script>

<style scoped>
p {
  margin: 8px 0;
}
</style>