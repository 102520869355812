<template>
    <div class="mb-4">
        <router-link class="primary-text" :to="{name: 'SingleUser', params: {id: user.id}}">
            <p>
                <i class="fa-solid fa-user user"></i>
                <span class="ms-3">
                    {{user.code}}
                </span> 
            </p>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'CompaniesUsers',
    props: ['user']
}
</script>

<style scoped>
.user {
  color: #67C23A; 
  height: 20px; 
}
</style>>