<template>
    <tr>
        <td>
            <router-link 
                class="primary-text" 
                :to="{name: 'Reconciliations', params: { id: reconciliation.id}}"
            >
                {{ reconciliation.id.substring(0,16) }}...
            </router-link>
        </td>  
        <td>
            {{  reconciliation.isActive }}
        </td>    
        <td>
            {{  reconciliation.status }}
        </td>
        <td v-if="reconciliation.isOk">
            {{ reconciliation.isOk }}
        </td>
        <td v-else>
            n / a
        </td>
        <td>
            {{ reconciliation.createdAt.toDateString().substring(4) }}
        </td>
        <td v-tippy="{content: reconciliation.id}">
            admin
        </td>
    </tr>
</template>

<script>
export default {
    name: 'RecListItem',
    props: ['reconciliation']
}
</script>